export default theme => ({
  footer: {
    zIndex: 2,
    bottom: 0,
    backgroundColor: '#ffffff',
    gridArea: 'footer',
  },
  footerBrandWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 0,
    paddingTop: 20,
    paddingLeft: 40,
  },
  footerBrandName: {
    textAlign: 'left',
    fontSize: 12,
  },
  footerUnorderedList: {
    textAlign: 'left',
    margin: 0,
    paddingTop: 10,
    paddingLeft: 40,
    paddingBottom: 20,
  },
  footerListItem: {
    padding: '3px 0px',
    fontSize: 11,
    display: 'inline-block',
    textAlign: '-webkit-match-parent',
  },
  footerListFirstChildAnchor: {
    borderLeft: 0,
    paddingLeft: 0,
    paddingRight: 11,
    textDecoration: 'none',
    background: 'transparent',
  },
  footerListAnchor: {
    borderLeft: '1px solid #333',
    padding: '0px 11px',
    color: '#333',
    textDecoration: 'none',
    background: 'transparent',
  },
});
