import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import style from './style';

class Text extends React.Component {

  render() {
    const { id, values, className } = this.props;
    return (
      <span style={{...style.defaultFont, ...this.props.style}} className={className}>
        {id ? <FormattedMessage id={id} values={values} /> : this.props.children }
      </span>
    )
  }
}

export default injectIntl(Text);
