import React, { Component } from 'react';
import { EnvEnum } from '../../enums/EnvEnum';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CompanyName } from '../../enums/Companies';
import Text from '../../elements/Text/Text';
import { privacyPolicyLinks, termsOfUseLinks } from '../Links/LinksInfo';
import styles from './styles';

class Footer extends Component {
  render() {
    const { country, classes } = this.props;
    let formattedCountry = country ? country.toLowerCase() : '';
    return (
      <div className={classes.footer}>
        <div className={classes.footerBrandWrapper}>
          <p
            className={
              classes.footerBrandName
            }>{`© ${new Date().getFullYear()} ${CompanyName(
            EnvEnum.BRAND,
          )}`}</p>
        </div>

        <ul className={classes.footerUnorderedList}>
          <li className={classes.footerListItem}>
            <a
              className={classes.footerListFirstChildAnchor}
              href='?imprint=1'
              target='_blank'
              rel='noopener noreferrer'>
              <Text id={'MS_IMPRINT'}>Impressum</Text>
            </a>
          </li>
          {country && (
            <li className={classes.footerListItem}>
              <a
                className={classes.footerListAnchor}
                href={privacyPolicyLinks[EnvEnum.BRAND][formattedCountry]}
                target='_blank'
                rel='noopener noreferrer'>
                <Text id={'MS_DATA_PROTECTION'}>Datenschutz</Text>
              </a>
            </li>
          )}
          {country && (
            <li className={classes.footerListItem}>
              <a
                className={classes.footerListAnchor}
                href={termsOfUseLinks[EnvEnum.BRAND][formattedCountry]}
                target='_blank'
                rel='noopener noreferrer'>
                <Text id={'MS_TERMS'}>Nutzungsbedingungen</Text>
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Footer);
