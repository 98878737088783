import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles.js';
import { FormattedMessage } from 'react-intl';

class MaintenanceComponent extends Component {
  render() {
    const { classes, endOfMaintenanceWindow } = this.props;
    return (
      <div
        data-testid='maintenance-component'
        className={classes.maintenanceContainer}>
        <h1>
          <FormattedMessage id='MS_MAINTENANCE_MESSAGE_TITLE'></FormattedMessage>
        </h1>
        <FormattedMessage
          id='MS_MAINTENANCE_MESSAGE'
          values={{
            endOfMaintenanceWindow: new Date(endOfMaintenanceWindow),
          }}></FormattedMessage>
      </div>
    );
  }
}

export default withStyles(styles)(MaintenanceComponent);

MaintenanceComponent.propTypes = {
  classes: PropTypes.object,
};
