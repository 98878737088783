import React, { Component } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectField from '../../elements/SelectField/index';
import Text from '../../elements/Text/index';
import { injectIntl } from 'react-intl';
import MuiPhoneNumber from 'material-ui-phone-number';
import styles from './styles';

const FORM_NAME = 'registrationForm';

class UserInputMask extends Component {
  state = {
    salutation: '',
    radioChecked: false,
    isPanelExpanded: false,
    notificationsMessage: null,
    isSubmitSuccessful: false,
  };

  isValidPostalCode = value => {
    this.messageMissingMandatoryValue(value);
    const regExp = this.props.country === 'DE' ? /^([0-9]{5})$/ : /^.*$/;
    return !regExp.test(value);
  };

  messageMissingMandatoryValue = value =>
    !value && `${this.props.intl.formatMessage({ id: 'MS_REQUIRED' })}`;

  phoneNumberValid = value => this.validatePhoneNumberLength(value);

  handleSelectChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.props.dispatch(change(FORM_NAME, name, value));
  };

  handleRadioChange = (event, checked) => {
    this.setState({ radioChecked: checked });
  };

  getMenuItems = (label, intl) => {
    switch (label) {
      case 'salutation':
        return [
          {
            label: intl.formatMessage({ id: 'MS_SALUTATION_LABEL' }),
            value: 'n',
          },
          {
            label: intl.formatMessage({ id: 'MS_SALUTATION_MR' }),
            value: 'Mr.',
          },
          {
            label: intl.formatMessage({ id: 'MS_SALUTATION_MRS' }),
            value: 'Mrs.',
          },
        ];
      default:
        break;
    }
  };

  renderSelectField = (value, label, intl) => (
    <SelectField
      className={this.props.classes.registrationFormSelect}
      label={label}
      value={value}
      onSelectChange={this.handleSelectChange}
      menuItems={this.getMenuItems(label, intl)}
    />
  );

  renderTextField = ({
    className,
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => (
    <TextField
      className={[this.props.classes.textField, className].join(' ')}
      label={label}
      placeholder={label}
      error={touched && !!error}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );

  validatePhoneNumberLength = (inputNumber, onlyCountries) => {
    let length = 0;
    if (inputNumber) {
      length = inputNumber.split(' ').join('').length;
    }
    if (length < 8) {
      return `${this.props.intl.formatMessage({ id: 'MS_PHONE_TOO_SHORT' })}`;
    } else if (length > 15) {
      return `${this.props.intl.formatMessage({ id: 'MS_PHONE_TOO_LONG' })}`;
    }
    return null;
  };

  renderPhoneInput = props => {
    const {
      meta: { touched, error },
    } = props;
    return (
      <MuiPhoneNumber
        inputClass={this.props.classes.phoneField}
        defaultCountry={props.country ? props.country.toLowerCase() : 'de'}
        containerstyle={props.phoneInputContainer}
        inputstyle={props.phoneInput}
        required={true}
        inputextraprops={{
          required: true,
        }}
        helperText={touched && error}
        error={touched && !!error}
        {...props.input}
        countryCodeEditable={false}
        onChange={event => {
          props.input.onChange('+' + event);
        }}
        regions={'europe'}
        buttonstyle={props.countrySelect}
        isValid={() => !(touched && error)}
      />
    );
  };

  formatPhoneNumber = phoneNumber => {
    return phoneNumber &&
      phoneNumber.length >= 3 &&
      phoneNumber.substring(1, 3) === '44'
      ? '00' + phoneNumber.substring(1)
      : phoneNumber;
  };

  requiredFields = [
    'MS_FIRSTNAME',
    'MS_LASTNAME',
    'MS_STREET',
    'MS_HOUSENUMBER',
    'MS_ZIPCODE',
    'MS_CITY',
    'MS_PHONE',
  ];

  getFieldLabel(i18nId) {
    let labelText = this.props.intl.formatMessage({ id: i18nId });
    if (this.requiredFields.indexOf(i18nId) !== -1) {
      labelText += '*';
    }
    return labelText;
  }

  render() {
    const { classes, email, intl, country } = this.props;
    const { salutation } = this.state;

    return (
      <div className={classes.userInputMask}>
        <FormControl className={classes.selectFormControl} variant='outlined'>
          <InputLabel
            shrink={true}
            htmlFor='salutation-label-placeholder'
            className={classes.inputLabel}>
            {this.getFieldLabel('MS_SALUTATION')}
          </InputLabel>
          <Field
            name='salutation'
            component={() =>
              this.renderSelectField(salutation, 'salutation', intl)
            }
          />
        </FormControl>
        <Field
          name='firstName'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_FIRSTNAME')}
          validate={this.messageMissingMandatoryValue}
        />
        <Field
          name='lastName'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_LASTNAME')}
          validate={this.messageMissingMandatoryValue}
        />
        <Field
          name='street'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_STREET')}
          validate={this.messageMissingMandatoryValue}
          className={classes.addressInputFields}
        />
        <Field
          name='houseNumber'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_HOUSENUMBER')}
          validate={this.messageMissingMandatoryValue}
          className={classes.addressInputFields}
        />
        <Field
          name='extension'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_ADDRESS_EXT')}
          className={classes.addressInputFields}
        />
        <Field
          name='postalCode'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_ZIPCODE')}
          validate={this.isValidPostalCode}
          className={classes.addressInputFields}
        />
        <Field
          name='city'
          component={this.renderTextField}
          label={this.getFieldLabel('MS_CITY')}
          validate={this.messageMissingMandatoryValue}
          className={classes.addressInputFields}
        />
        <FormControl className={'phone-control'} variant='outlined'>
          <InputLabel shrink={true} className={classes.phoneLabel}>
            {this.getFieldLabel('MS_PHONE')}
          </InputLabel>
          <Field
            name='phone'
            component={this.renderPhoneInput}
            country={country}
            label={this.getFieldLabel('MS_PHONE')}
            {...{
              phoneInput: classes.phoneInput,
              countrySelect: classes.countrySelect,
              phoneInputContainer: classes.phoneInputContainer,
              backgroundColor: 'transparent',
            }}
            validate={this.phoneNumberValid}
          />
        </FormControl>
        <InputLabel
          shrink={true}
          htmlFor='email-label-placeholder'
          className={classes.emailLabel}>
          E-Mail
        </InputLabel>
        <div className={classes.emailText}>{email}</div>
        <Text className={classes.requiredText} id='MS_REQUIRED_EXPLANATION' />
      </div>
    );
  }
}

UserInputMask.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  company: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  personalDataRequired: PropTypes.bool,
  onRegistrationSuccess: PropTypes.func,
};

export default reduxForm({
  form: FORM_NAME,
})(withStyles(styles)(injectIntl(UserInputMask)));
