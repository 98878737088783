import { consentDeclarationDeVaillant } from './consentDeclarations/deVaillant';
import { consentDeclarationAtVaillant } from './consentDeclarations/atVaillant';
import { consentDeclarationGbVaillant } from './consentDeclarations/gbVaillant';
import { consentDeclarationFrVaillant } from './consentDeclarations/frVaillant';
import { consentDeclarationFrSaunierDuval } from './consentDeclarations/frSaunierDuval';
import { consentDeclarationNlVaillant } from './consentDeclarations/nlVaillant';
import { consentDeclarationNlAwb } from './consentDeclarations/nlAwb.js';
import { constentDeclarationRoVaillant } from './consentDeclarations/roVaillant';
import { consentDeclarationHrVaillant } from './consentDeclarations/hrVaillant';
import { consentDeclarationItVaillant } from './consentDeclarations/itVaillant';
import { consentDeclarationItHermannSaunierDuval } from './consentDeclarations/itHermannSaunierDuval';
import { consentDeclarationBeVaillant } from './consentDeclarations/beVaillant';
import { consentDeclarationBeBulex } from './consentDeclarations/beBulex';
import { consentDeclarationDkVaillant } from './consentDeclarations/dkVaillant';

const privacyPolicies = {
  de: consentDeclarationDeVaillant,
  at: consentDeclarationAtVaillant,
  gb: consentDeclarationGbVaillant, // no separate declaration for glow-worm present -> vaillant is used for both. ToDo: clarify if this is correct
  es: {
    vaillant: `<h1>TODO</h1>`,
  },
  be: {
    vaillant: consentDeclarationBeVaillant,
    bulex: consentDeclarationBeBulex,
  },
  fr: {
    vaillant: consentDeclarationFrVaillant,
    saunierduval: consentDeclarationFrSaunierDuval,
  },
  nl: {
    vaillant: consentDeclarationNlVaillant,
    awb: consentDeclarationNlAwb,
  },
  ro: constentDeclarationRoVaillant,
  hr: consentDeclarationHrVaillant,
  it: {
    vaillant: consentDeclarationItVaillant,
    'hermann-saunierduval': consentDeclarationItHermannSaunierDuval,
  },
  dk: consentDeclarationDkVaillant,
};

export { privacyPolicies };
