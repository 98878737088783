import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { AppStore } from './reducers';
// import locale from './i18n';
// import languages from './i18n/languages';
// import { initialize, addTranslation } from 'react-localize-redux';
// import './resources/css/index.css';
// import Root from './containers/Root';
// import registerServiceWorker from './registerServiceWorker';
// import { getLanguage } from './helpers/LocalStorage'

// AppStore.dispatch(initialize(languages, { defaultLanguage: getLanguage() || 'de' }));
// AppStore.dispatch(addTranslation(locale));

// ReactDOM.render(
//   <Provider store={AppStore}>
//     <Root />
//   </Provider>,
//   document.getElementById('root')
// );
//registerServiceWorker();
