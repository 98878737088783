const consentDeclarationNlAwb = `
<h1>Toestemmingsformulier</h1>
<h2>Lees dit formulier zorgvuldig en klik hierboven als u akkoord gaat met de inhoud ervan.</h2>
<ol>
 <li>
  <p>
   In dit formulier vragen wij uw toestemming voor de verwerking en overdracht van en de toegang tot uw persoonsgegevens door uw dienstverlener (‘partner’), met het oog op de controle, de diagnose en het onderhoud van uw AWB verwarmingssystem.
  </p>
 </li>
 <li>
  <p>
   Wij verzamelen en verwerken uw persoonsgegevens via een inbelverbinding van een van onze internetmodules MiLink of MiGo Link (‘gateway’), die wordt aangesloten op uw AWB verwarmingssysteem en volgens het privacy beleid dat tussen u en ons werd overeengekomen. Wenst u meer informatie over uw rechten en het type van gegevens die wij verzamelen en verwerken, raadpleeg dan de voorwaarden van de gateway app en het privacy beleid via de onderstaande link.
  </p>
 </li>
 <li>
  <p>
   Wij hebben uw partner voorzien van software waarmee hij toegang krijgt tot en gebruik kan maken van de gegevens die van uw gateway naar onze databank worden gestuurd, en ook verbinding kan maken met uw AWB verwarmingssysteem. Uw partner kan op deze manier informatie verzamelen over uw gebruik van het AWB verwarmingssysteem (onder meer over de werkingsstatus, het in- en/of uitschakelen, het verbruik, foutmeldingen, indicaties van storingen, sensorwaarden, meters/tellers en actuatorstatus, werkingsperimeters en doorlooptijden, tijdsblokken, login en informatie over incidenten), instellingen en profielen wijzigen en uw verwarming met dat doel regelen terwijl ze verbonden is tijdens de diensten die hij aan u verleent. Door uw gegevens te verwerken/raadplegen, kan uw partner u dan ook zijn diensten verlenen en een diagnose stellen, uw verwarmingssysteem herstellen en/of onderhouden, en/of u in staat stellen om uw AWB verwarmingssysteem op afstand te bedienen met de door uw partner geleverde applicatie.
  </p>
 </li>
 <li>
  <p>
   Door uw toestemming te verlenen, verzekert u ons dat u vooraf toestemming hebt gekregen van elke andere gebruiker van het AWB verwarmingssysteem, indien en voor zover u niet de enige verantwoordelijke eigenaar bent van het AWB verwarmingssysteem en de gateway, bijvoorbeeld als een andere huiseigenaar bij u woont.
  </p>
 </li>
 <li>
  <p>
   Uw persoonsgegevens worden verwerkt binnen de Europese Economische Ruimte (EER), behoudens andersluidende mededeling van onze kant. Wend u tot uw partner voor informatie over andere plaatsen waar uw gegevens door hem worden verwerkt.
  </p>
 </li>
 <li>
  <p>
   U mag uw toestemming voor het verwerken van uw persoonsgegevens en voor de toegang tot uw verwarmingssysteem met het oog op controle en onderhoud op elk ogenblik intrekken. Daartoe klikt u op de link in het e-mailbericht dat u van AWB heeft ontvangen, of door met ons contact op te nemen via <a href="mailto:controls@vaillant.nl">controls@vaillant.nl</a>
  </p>
 </li>
 <li>
  <p>
   Door uw toestemming in te trekken, bestaat echter de kans dat uw partner geen onderhoudswerken kan uitvoeren en geen problemen kan oplossen die zich voordoen aan uw AWB verwarmingssysteem, aangezien hij het systeem niet op afstand kan controleren en analyseren.
  </p>
 </li>
 <li>
  <p>
   Als u vragen heeft over dit toestemmingsformulier en/of over de verwerking van uw persoonsgegevens, kan u met ons contact opnemen via <a href="mailto:controls@vaillant.nl">controls@vaillant.nl</a>
 </p>
 </li>
 <li>
  <p>
   Uw rechten worden beschreven in het privacy beleid via de onderstaande link. U heeft ook het recht om eender wanneer een klacht in te dienen bij de Gegevensbeschermingsautoriteit, de toezichthouder voor aangelegenheden rond gegevensbescherming (<a href="https://autoriteitpersoonsgegevens.nl/nl">https://autoriteitpersoonsgegevens.nl/nl</a>)
  </p>
 </li>
 <li>
  <p>
   Wij zouden het echter op prijs stellen als wij de kans krijgen om uw klacht te behandelen voordat u zich tot deze instantie wendt. Gelieve dus eerst met ons contact op te nemen.
  </p>
 </li>
 <li>
  <p>
   Het is belangrijk dat de persoonsgegevens die wij over u bezitten, correct en up-to-date zijn. Gelieve ons op de hoogte te brengen indien uw persoonsgegevens tijdens onze relatie veranderen.
  </p>
 </li>
</ol>
`;

export { consentDeclarationNlAwb };
