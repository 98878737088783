import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CompanyLogo } from '../../Images';
import { EnvEnum } from '../../enums/EnvEnum';
import Text from '../../elements/Text';
import styles from './styles';

import SelectLanguage from '../SelectLanguage/index';

function BrandSlogan(props) {
  const brand = props.brand;
  const classes = props.classes;
  if (brand === 'vaillant') {
    return <Text id={'MS_VAILLANT_LOGO_MOTO'}>Komfort für mein Zuhause</Text>;
  } else {
    return <p className={classes.sloganPlaceholder} />;
  }
}
class Header extends React.Component {
  render() {
    const classes = this.props.classes;
    return (
      <Fragment>
        <AppBar className={classes.appBar}>
          <div className={classes.headerLogoContainer}>
            <img src={CompanyLogo} alt='logo' width={150} />
            <p className={classes.headerLogoSloganSeparator} />
            <BrandSlogan brand={EnvEnum.BRAND} classes={classes}></BrandSlogan>
          </div>
          <div className={classes.languageSelector}>
            <SelectLanguage></SelectLanguage>
          </div>
        </AppBar>
      </Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Header);
