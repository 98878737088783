import ThemeColors from '../../Colors/index';

export default theme => ({
  userInputMask: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  registrationFormSelect: {
    border: `1px solid ${ThemeColors.INPUT_BORDER}`,
    borderRadius: 5,
    height: 40,
    textAlign: 'left',
    paddingLeft: 10,
  },
  textField: {
    marginTop: 20,
    maxWidth: 600,
  },
  phoneField: {
    marginTop: 38,
    maxWidth: 600,
  },
  selectFormControl: {
    maxWidth: 600,
    textAlign: 'left',
  },
  inputLabel: {
    left: '-.75em',
  },
  emailLabel: {
    textAlign: 'left',
    marginTop: 40,
  },
  emailText: {
    marginTop: 10,
    textAlign: 'left',
    color: ThemeColors.GRAY_LIGHT,
    borderBottom: `1px dashed ${ThemeColors.GRAY_LIGHT}`,
    maxWidth: 600,
  },
  addressInputFields: {
    textAlign: 'left',
  },
  phoneLabel: {
    top: '1.5em',
    left: '-1em',
  },
  phoneInput: {
    marginTop: 30,
    textAlign: 'left',
    width: '100%',
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: '1px',
    borderBottomColor: ThemeColors.GRAY_LIGHT,
  },
  countrySelect: {
    borderWidth: 0,
    backgroundColor: ThemeColors.WHITE,
  },
  phoneInputContainer: {
    flex: 1,
    maxWidth: 600,
    marginTop: 30,
  },
  requiredText: {
    marginTop: 30,
    textAlign: 'left',
    color: ThemeColors.GRAY_LIGHT,
    fontStyle: 'italic',
  },
});
