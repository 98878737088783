import React, { Component } from 'react';
import Svg from 'svgs';
import SvgIcons from './Icons';
import ThemeColors from '../../Colors/index';

class SvgIcon extends Component {
  render() {
    const { height, width, icon, fill } = this.props;
    return (
      <Svg
        height={height}
        width={width}
        viewBox={SvgIcons[icon].viewBox}
        fill={fill || ThemeColors.BLACK}>
        {SvgIcons[icon].svg}
      </Svg>
    );
  }
}
export default SvgIcon;
