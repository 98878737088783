import ThemeColors from '../../Colors';

export default themes => ({
  text: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  webLink: {
    textDecoration: 'none',
    color: ThemeColors.PRIMARY,
    fontWeight: 'bold',
  },
  successContainer: {
    display: 'flex',
    flex: 1,
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '30px 40px',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: '650px',
    minWidth: '300px',
    top: '20%',
  },
});
