import ThemeColors from '../../Colors/Green/ThemeColors';

export default theme => ({
  container: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: 5,
    padding: '30px 40px',
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translate(-50%,-50%)',
  },
  modalText: {
    margin: 5,
  },
  emailAddressText: {
    color: ThemeColors.ACTION,
  },
});
