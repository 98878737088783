import { EnvEnum } from '../enums/EnvEnum';
import { Companies } from '../enums/Companies';

const CompanyLogos = {
  [Companies.VAILLANT]: process.env.PUBLIC_URL + '/images/vaillant-logo.png',
  [Companies.AWB]: process.env.PUBLIC_URL + '/images/awb-logo.png',
  [Companies.BULEX]: process.env.PUBLIC_URL + '/images/bulex-logo.png',
  [Companies.GLOWWORM]: process.env.PUBLIC_URL + '/images/glow-worm-logo.png',
  [Companies.SAUNIER_DUVAL]:
    process.env.PUBLIC_URL + '/images/saunier-duval-logo.png',
  [Companies.HERMANN_SAUNIER_DUVAL]:
    process.env.PUBLIC_URL + '/images/hsd-logo.png',
};

const CompanyImageClump = {
  [Companies.VAILLANT]: {
    highRes: `${process.env.PUBLIC_URL}/images/green_brand_picture.png`,
    lowRes: `${process.env.PUBLIC_URL}/images/highcompress-green_brand_picture.png`,
  },
  [Companies.AWB]: {
    highRes: `${process.env.PUBLIC_URL}/images/red_brand_picture.png`,
    lowRes: `${process.env.PUBLIC_URL}/images/highcompress-red_brand_picture.png`,
  },
  [Companies.BULEX]: {
    highRes: `${process.env.PUBLIC_URL}/images/red_brand_picture.png`,
    lowRes: `${process.env.PUBLIC_URL}/images/highcompress-red_brand_picture.png`,
  },
  [Companies.GLOWWORM]: {
    highRes: `${process.env.PUBLIC_URL}/images/red_brand_picture.png`,
    lowRes: `${process.env.PUBLIC_URL}/images/highcompress-red_brand_picture.png`,
  },
  [Companies.SAUNIER_DUVAL]: {
    highRes: `${process.env.PUBLIC_URL}/images/red_brand_picture.png`,
    lowRes: `${process.env.PUBLIC_URL}/images/highcompress-red_brand_picture.png`,
  },
  [Companies.HERMANN_SAUNIER_DUVAL]: {
    highRes: `${process.env.PUBLIC_URL}/images/red_brand_picture.png`,
    lowRes: `${process.env.PUBLIC_URL}/images/highcompress-red_brand_picture.png`,
  },
};

const CompanyImage = CompanyImageClump[EnvEnum.BRAND];
const CompanyLogo = CompanyLogos[EnvEnum.BRAND];

export { CompanyImage, CompanyLogo };
