const consentDeclarationDeVaillant = `<h1>Einwilligungserklärung zur Datenverarbeitung durch Ihren Dienstleister</h1>
<h2>Bitte lesen Sie dieses Einwilligungsformular sorgfältig durch und klicken Sie oben, um Ihr Einverständnis mit diesen Bedingungen zu bestätigen</h2>
<ol>
  <li>
    <p>Mit dieser Einwilligungserklärung möchten wir Ihr Einverständnis für die Verarbeitung und Übermittlung sowie den Zugriff auf Ihre personenbezogenen Daten durch Ihren Dienstleister ("Partner") zum Zwecke der digitalen Fernbetreuung und Diagnose und Wartung Ihres Vaillant Heizsystems einholen.
    </p>
  </li>

  <li>
    <p>Wir erheben und verarbeiten Ihre personenbezogenen Daten über einen DFÜ-Zugang eines unserer Internetmodule eRELAX und myVAILLANT connect ("Gateway"), der an Ihr Vaillant Heizsystem angeschlossen wird und gemäß der zwischen Ihnen und uns vereinbarten  Datenschutzerklärung. Weitere Informationen zu Ihren Rechten und der Art der Daten, die wir erheben undverarbeiten, entnehmen Sie bitte den jeweiligen Bedingungen der Gateway Appund der Datenschutzerklärung, die Sie in der E-Mail zu dieser Einwilligungserklärung verlinkt finden.
    </p></li>

  <li>
    <p>Wir haben Ihrem Partner eine Software zur Verfügung gestellt, mit der er auf die von Ihrem Gateway an unsere Datenbank gesendeten Daten zugreifen und diese nutzen  kann  sowie eine  Verbindung  zu Ihrem Vaillant  Heizsystemherstellen kann. Dies ermöglicht  Ihrem  Partner, Informationen  über Ihre Nutzung des Vaillant Heizsystems (insbesondere Betriebszustand, Ein- und/oder Ausschalten, Verbrauch, Fehlermeldung, Störungsanzeige, Sensorwerte, Zähler und Betriebsstatus, Betriebsparameter und Laufzeiten, Zeitprogramme, Loginund Informationen über Vorfälle) zu sammeln. Zu diesem Zweck kann Ihr Partner für Sie Leistungen erbringen, kann Zugriff auf Ihre Profile/Einstellungen im Gateway/Heizsystem nehmen oder ändern und Ihre Heizung fernsteuern. Durch die Verarbeitung/Zugriff auf Ihre Daten kann Ihr Partner ggf. Ihr Heizsystem diagnostizieren und soweit möglich bereits remote reparieren und/oder warten und/oder Ihnen ermöglichen, Ihr Vaillant Heizsystem über eine von IhremPartner bereitgestellte Anwendung fernzusteuern.
    </p>
  </li>
  <li>
    <p>Mit der Erteilung dieser Einwilligung versichern Sie uns, dass Sie die vorherige Zustimmung jedes anderen Nutzers des Vaillant Heizsystems eingeholthaben, wenn und soweit Sie nicht der allein verantwortliche Eigentümer des Vaillant Heizsystems  und  des Gateways sind,  z.B.  wenn ein anderer Hauseigentümer mit Ihnen zusammenlebt. </p>
  </li>

  <li><p>Über die Grundsätze und die Art und Weise der Verarbeitung Ihrer Daten durch Ihren Partner, wenden Sie sich bitte an diesen. </p>
  </li>

  <li>
    <p>Sie können Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten und zum Zugriff auf Ihre Heizungsanlage in Bezug auf deren digitale Fernbetreuung und Wartung jederzeit widerrufen. Dies können Sie durch Anklicken des Links  in  der erhaltenen E-Mail von Vaillant  oder durch Kontaktaufnahme mit uns <a href="mailto:datenschutz@vaillant-group.com">datenschutz@vaillant-group.com</a> durchführen. Bitte beachten Sie jedoch, dass Ihr Partner durch den Widerruf Ihrer Einwilligung möglicherweise nicht  mehr in der Lage ist, Wartungsarbeiten  oder eineStörungsbeseitigung an Ihrer Vaillant Heizungsanlage durchzuführen, da er nicht in der Lage ist, die Heizungsanlage aus der Ferne zu überwachen/auf sie zuzugreifen.
  </p>
  </li>

  <li><p>Sollten Sie  Fragen zu dieser Einwilligungserklärung  und/oder der Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte an <a href="mailto:datenschutz@vaillant-group.com">datenschutz@vaillant-group.com</a></p>
  </li>

  <li><p>Ihre Rechte sind in der verlinkten Datenschutzrichtlinie (vgl. beiliegende E-Mail) aufgeführt. Sie haben auch das Recht, jederzeit eine Beschwerde beim Landesbeauftragten für Datenschutz und Informationsfreiheit Nordrhein-Westfalen, deren aktuelle Kontaktdaten Sie  unter <a href="https://www.ldi.nrw.de">www.ldi.nrw.de</a> finden, einzureichen. Wir würden uns jedoch freuen, wenn wir Ihre Bedenken ausräumen könnten, bevor Sie sich an die Behörde wenden, also kontaktieren Sie uns bitte zunächst.</p></li>
  <li>
    <p>
      Es ist wichtig, dass die persönlichen Daten, die wir über Sie haben, richtigund aktuell sind. Bitte informieren Sie uns, wenn sich Ihre persönlichen Daten ändern.
    </p>
  </li>

</ol>`;

export { consentDeclarationDeVaillant };
