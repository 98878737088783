// see https://groupspace.vaillant-group.com/pages/viewpage.action?pageId=131516231 for additional flavors/countries

const termsOfUseLinks = {
  awb: {
    nl: 'https://www.awb.nl/over-awb/leverings-en-betalingsvoorwaarden/',
  },
  bulex: {
    be: 'https://www.bulex.be/over-bulex/algemeen/verkoopsvoorwaarden/',
  },
  saunierduval: {
    fr:
      'https://www.saunierduval.fr/saunier-duval/mentions-legales-366411.html',
  },
  'glow-worm': {
    gb:
      'https://www.glow-worm.co.uk/homeowner/repairs-and-servicing/guarantee/guarantee-terms-and-conditions-223455.html',
  },
  'hermann-saunierduval': {
    it: 'https://www.hermann-saunierduval.it/meta/disclaimer/',
  },
  vaillant: {
    at: 'https://www.vaillant.at/nutzungsbedingungen/',
    be: 'https://www.vaillant.be/over/algemene-informatie/verkoopsvoorwaarden/',
    de: 'https://www.vaillant.de/ueber-uns/nutzungsbedingungen/',
    es: 'https://www.vaillant.es/sobre-vaillant/legal/',
    fr: 'https://www.vaillant.fr/technical-pages/mentions-legales-387184.html',
    gb: 'https://www.vaillant.co.uk/about-vaillant/legal-disclaimer/',
    it: 'https://www.vaillant.it/contatti/condizioni-di-utilizzo/',
    nl:
      'https://www.vaillant.nl/over-vaillant/leveringsvoorwaarden-507691.html',
    dk: 'https://www.vaillant.dk/kunder/anvendelsesbetingelser-58004.html',
  },
};

const privacyPolicyLinks = {
  awb: {
    nl: 'https://www.awb.nl/over-awb/privacy-1215582.html',
  },
  bulex: {
    be: 'https://www.bulex.be/over-bulex/algemeen/gegevensbescherming',
  },
  saunierduval: {
    fr:
      'https://www.saunierduval.fr/saunier-duval/politiques-de-confidentialite-1227191.html',
  },
  'glow-worm': {
    gb: 'https://www.glow-worm.co.uk/about-us/privacy-policy/',
  },
  'hermann-saunierduval': {
    it: 'https://www.hermann-saunierduval.it/meta/privacy/',
  },
  vaillant: {
    at: 'https://www.vaillant.at/datenschutz/',
    be:
      'https://www.vaillant.be/over/algemene-informatie/bescherming-persoonlijke-levenssfeer/',
    de: 'https://www.vaillant.de/ueber-uns/datenschutz/',
    es: 'https://www.vaillant.es/sobre-vaillant/legal/politica-de-privacidad/',
    fr:
      'https://www.vaillant.fr/technical-pages/politiques-de-confidentialite-1228683.html',
    gb: 'https://www.vaillant.co.uk/about-vaillant/privacy-policy/',
    it: 'https://www.vaillant.it/contatti/privacy/',
    nl: 'https://www.vaillant.nl/over-vaillant/privacy-281153.html',
    dk: 'https://www.vaillant.dk/kunder/privatlivsvilk-r-1254858.html',
  },
};

export { privacyPolicyLinks, termsOfUseLinks };
