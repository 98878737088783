import React from 'react';
import Parser from 'html-react-parser';
import { privacyPolicies } from '../../enums/CountryInfo';

const PrivacyPolicy = ({ country, brand }) => {
  let policy = privacyPolicies[country.toLowerCase()][brand];
  policy = policy || privacyPolicies[country.toLowerCase()]; // fall back to country, if no policy for specific brand is given
  return <div style={{ textAlign: 'left' }}>{Parser(policy)}</div>;
};

export default PrivacyPolicy;
