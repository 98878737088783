import axios from 'axios';
import { getApiRoot, getBrand, getSubscriptionKey } from './config';

const apiUrl = getApiRoot();
const subKey = getSubscriptionKey();
const brand = getBrand();

const axiosInstance = axios.create({
  baseURL: apiUrl,
  transformRequest: [data => JSON.stringify(data)],
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': subKey,
    'X-Brand': brand,
    'X-Client': 'consent-portal',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Brand': brand,
      },
    };
  },
  error => error,
);

axiosInstance.interceptors.response.use(response => {
  return response;
});

export default axiosInstance;
