import ThemeColors from '../../Colors/index';

export default theme => ({
  activitySpinner: {
    backgroundColor: ThemeColors.PRIMARY,
  },
  container: {
    width: '100%',
    height: '100%',
    gridArea: 'main',
    position: 'relative',
  },
  imageContainer: {
    position: 'absolute',
    zIndex: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      visibility: 'visible',
    },
  },
  heroImage: {
    height: '100%',
    width: '100%',
    maxWidth: 1920,
    overflow: 'hidden',
  },
  registrationFormContainer: {
    position: 'relative',
    zIndex: 1,
    top: 75,
    marginBottom: 125,
    minWidth: 300,
    maxWidth: 672,
    backgroundColor: 'rgba(255, 255, 255, 0.94)',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: 0,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '85%',
      left: '7.5%',
    },
    [theme.breakpoints.only('lg')]: {
      width: '70%',
      left: '15%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
      left: '15%',
    },
  },
  gdprDetailsTitleContainer: {
    textAlign: 'left',
    padding: '40px 40px 20px 40px',
    fontSize: 26,
    fontWeight: 'bold',
  },
  gdprDetailsSubTitleContainer: {
    textAlign: 'left',
    padding: '20px 40px',
    fontSize: 18,
  },
});
