import React from 'react';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import ThemeColors from '../../Colors/index';

const SelectField = ({
  className,
  value,
  label,
  onSelectChange,
  menuItems,
}) => (
  <Select
    value={value}
    onChange={onSelectChange}
    input={<Input name={label} id={`${label}-label-placeholder`} />}
    displayEmpty={true}
    name={label}
    className={className}
    style={{ color: ThemeColors.GRAY_LIGHT }}>
    {menuItems.map((item, index) => (
      <MenuItem
        disabled={index === 0}
        selected={index === 0}
        key={item.value}
        value={index === 0 ? '' : item.value}
        style={index === 0 ? { color: ThemeColors.GRAY_LIGHT } : {}}>
        {item.label}
      </MenuItem>
    ))}
  </Select>
);

SelectField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default SelectField;
