import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { messages } from '../../i18n/index';
import Header from '../Header';
import MainContent from '../MainContent';
import Footer from '../Footer';
import { OptInClient } from '../../api';
import './root.css';

class Root extends React.Component {
  state = {
    maintenance: false,
    endOfMaintenanceWindow: null,
    error: false,
    loading: true,
    imprint: false,
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const imprint = urlParams.get('imprint');
    const token = urlParams.get('token');

    if (imprint) {
      this.setState({ loading: false, imprint: true });
    } else {
      const maintenanceWindow = await OptInClient.hasActiveMaintenanceWindow(
        token,
      );
      if (maintenanceWindow) {
        this.setState({
          maintenance: true,
          endOfMaintenanceWindow: maintenanceWindow.toUTCString(),
          loading: false,
        });
      } else {
        const data = await OptInClient.getPrefillData(token);
        this.setState({
          ...data,
          loading: false,
        });
      }
    }
  }

  render() {
    const language = this.props.language;

    return (
      <IntlProvider
        locale={language}
        messages={messages[language]}
        defaultMessages={messages['en']}
        defaultLocale='en'>
        <Header class='header' />
        <MainContent
          class='main'
          loading={this.state.loading}
          company={this.state.company}
          country={this.state.country}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          personalDataRequired={this.state.personalDataRequired}
          error={this.state.error}
          consentGiven={this.state.consentGiven}
          token={this.state.token}
          status={this.state.status}
          imprint={this.state.imprint}
          maintenance={this.state.maintenance}
          endOfMaintenanceWindow={this.state.endOfMaintenanceWindow}
        />
        <Footer class='footer' country={this.state.country} />
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  language: state.appReducer.language,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
