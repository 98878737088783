import React from 'react';

const isReactComponent = value => React.isValidElement(value);
const placeholderRegex = /(\{[\d|\w]+\})/;

export const formatStrings = (str, ...valuesForPlaceholders) => {
  let hasObject = false;
  const res = (str || '')
    .split(placeholderRegex)
    .filter(textPart => !!textPart)
    .map((textPart, index) => {
      if (textPart.match(placeholderRegex)) {
        const matchedKey = textPart.slice(1, -1);
        let valueForPlaceholder = valuesForPlaceholders[matchedKey];

        // If no value found, check if working with an object instead
        if (valueForPlaceholder === undefined) {
          const valueFromObjectPlaceholder =
            valuesForPlaceholders[0][matchedKey];
          if (valueFromObjectPlaceholder !== undefined) {
            valueForPlaceholder = valueFromObjectPlaceholder;
          } else {
            // If value still isn't found, then it must have been undefined/null
            return valueForPlaceholder;
          }
        }

        if (isReactComponent(valueForPlaceholder)) {
          hasObject = true;
          return React.Children.toArray(valueForPlaceholder).map(component => ({
            ...component,
            key: index.toString(),
          }));
        }

        return valueForPlaceholder;
      }
      return textPart;
    });
  if (hasObject) return res;
  return res.join('');
};
