const consentDeclarationGbVaillant = `<h1>Consent Form</h1>
<p>Please read this Consent Form carefully and click below to confirm your agreement to its terms.</p>
<ol>
  <li>This Consent Form seeks to obtain your consent to the processing of your personal data by Vaillant GmbH (Vaillant / us / our) and the installer of your Vaillant heating system (your Installer) for the purposes of monitoring your Vaillant heating system.
  </li>
  <li>We will collect your personal data via remote monitoring using a dial-up access of the internet module eRelax, which will be connected to your Vaillant heating system (Device).
  </li>
  <li>Once the Device has been connected to your Vaillant heating system, it will allow us to collect information about your use of the Vaillant heating system (including without limitation, the operating status, switching on and/or off, consumption, error message, fault indication, sensor values, metres/counters and actuator status, operating perimeters and run times, time programmes, login and information of incidents). We will hold and process your personal data for the purposes of installing and monitoring the Device and discussing any concerns relating to the Vaillant heating system with you. 
  </li>
  <li>The personal data that we will process about you is your name, address, mobile phone number and email address.
  </li>Your personal data will be processed within the European Economic Area (EEA), unless we inform you otherwise. About further locations of the processing of your data by your partner, please refer to him.
  </li>
  <li>You may revoke your consent for the processing of your personal data in relation to the monitoring of the Device at any time. You can do this by visiting our website at <a href="${window.location}">${window.location}</a> or by contacting us
   <a href="mailto:ukdigitaltech@vaillant-group.com">ukdigitaltech@vaillant-group.com</a>. However, please note that by revoking your consent Vaillant may be unable to provide the maintenance work or troubleshooting services for your Vaillant heating system as it is unable to remotely monitor the heating system. 
  </li>
  <li>In the event that you have any queries in relation to this Consent Form and/or the processing of your personal data by Vaillant or its third party partners, please contact us at <a href="mailto:ukdigitaltech@vaillant-group.com">ukdigitaltech@vaillant-group.com</a>.
  </li>
  <li>You also have the right to make a complaint about how your personal data is processed at any time to the Information Commissioner’s Office (ICO) which is the supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance. 
  </li>
  <li>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us. 
</ol>

<p><b>I agree to Vaillant GmbH using my personal data as described in the consent form above</b></p>`;

export { consentDeclarationGbVaillant };
