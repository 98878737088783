import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Text from '../../elements/Text/index';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { injectIntl } from 'react-intl';
import styles from './styles';

const FORM_NAME = 'registrationForm';

class UserInfoMask extends Component {
  state = {
    salutation: '',
    radioChecked: false,
    isPanelExpanded: false,
    notificationsMessage: null,
    isSubmitSuccessful: false,
  };

  renderTextField = ({
    className,
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => (
    <TextField
      className={[this.props.classes.textField, className].join(' ')}
      label={label}
      placeholder={label}
      error={touched && !!error}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );

  getFieldLabel(i18nId) {
    return this.props.intl.formatMessage({ id: i18nId });
  }

  render() {
    const { classes, email, firstName, lastName } = this.props;

    return (
      <div className={classes.userInfoMask}>
        <div className={classes.userInfoTitleContainer}>
          <Text id='MS_USERINFO_TITLE' className={classes.userInfoTitle} />
        </div>
        <InputLabel
          shrink={true}
          htmlFor='email-label-placeholder'
          className={classes.inputLabel}>
          {this.getFieldLabel('MS_NAME')}
        </InputLabel>
        <div className={classes.simpleText}>
          {firstName} {lastName}
        </div>
        <InputLabel
          shrink={true}
          htmlFor='email-label-placeholder'
          className={classes.inputLabel}>
          {this.getFieldLabel('MS_EMAIL')}
        </InputLabel>
        <div className={classes.simpleText}>{email}</div>
      </div>
    );
  }
}

UserInfoMask.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  company: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  personalDataRequired: PropTypes.bool,
  onRegistrationSuccess: PropTypes.func,
};

export default reduxForm({
  form: FORM_NAME,
})(withStyles(styles)(injectIntl(UserInfoMask)));
