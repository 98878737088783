const ThemeColors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  TRANSPARENT: 'transparent',
  ACTION: '#FF8500',
  PRIMARY: '#00917E',
  //SECONDARY: '#294447',
  INACTIVE: '#CCCCCC',
  SIGNAL: '#00917E',
  BLACK_SOFT: '#0C0033',
  GRAY_WHITE: '#F1F1F1',
  GRAY_LIGHT: '#9B9B9B',
  WIDGET_FOOTER: '#294447',
  PLACEHOLDER_TEXT: 'rgba(12,0,51,0.5)',
  INPUT_BORDER: '#C2BFCC',
  CONTAINER_BORDER: 'rgba(0, 0, 0, 0.7)',
  CONTAINER_BORDER_LIGHT: 'rgba(128, 128, 128, 0.3)',
  GRID_ROW_BACKGROUND: '#F5F5F6',
  ERROR: '#cc0033',
  BACKGROUND_COLOR_GRAY: '#f2f0f2',
  CUSTOMER_BACKGROUND_COLOR: '#00D2A720',
  CUSTOMER_LIST_BACKGROUND_COLOR: 'rgba(204, 0, 51, 0.15)',
  CUSTOMER_DETAILS_BACKGROUND_COLOR: '#E9E9E9',
  CUSTOMER_DETAILS_BOX_SHADOW: 'inset 0px 20px 18px -10px #bbb, inset 0px -20px 18px -10px #bbb',
};

export default ThemeColors;
