export const Companies = {
  VAILLANT: 'vaillant',
  AWB: 'awb',
  BULEX: 'bulex',
  GLOWWORM: 'glow-worm',
  SAUNIER_DUVAL: 'saunierduval',
  HERMANN_SAUNIER_DUVAL: 'hermann-saunierduval',
};

const CompanyNames = {
  AWB: 'AWB',
  BULEX: 'Bulex',
  GLOW_WORM: 'Glow-worm',
  HERMANN_SAUNIERDUVAL: 'Hermann Saunier Duval',
  SAUNIERDUVAL: 'Saunier Duval',
  VAILLANT: 'Vaillant',
};

const CompaniesHomePages = {
  VAILLANT: {
    AT: 'https://www.vaillant.at',
    DE: 'https://www.vaillant.de',
    ES: 'https://www.vaillant.es',
    FR: 'https://www.vaillant.fr',
    BE: 'https://www.vaillant.be',
    GB: 'https://www.vaillant.co.uk',
    HR: 'https://www.vaillant.hr',
    NL: 'https://www.vaillant.nl',
    RO: 'https://www.vaillant.com.ro',
    IT: 'https://www.vaillant.it',
    DK: 'https://www.vaillant.dk',
  },
  AWB: 'https://www.awb.nl',
  BULEX: 'https://www.bulex.be/',
  GLOW_WORM: 'https://www.glow-worm.co.uk/',
  HERMANN_SAUNIERDUVAL: 'https://www.hermann-saunierduval.it',
  SAUNIERDUVAL: {
    FR: 'https://www.saunierduval.fr',
    ES: 'https://www.saunierduval.es',
    RO: 'https://www.saunierduval.ro',
  },
};

export const CompanyName = company => {
  return CompanyNames[
    company
      .toUpperCase()
      .split('-')
      .join('_')
  ];
};

export const CompanyHomePage = (company, country = '') => {
  const companyLink =
    CompaniesHomePages[
      company
        .toUpperCase()
        .split('-')
        .join('_')
    ];
  return typeof companyLink === 'string'
    ? companyLink
    : companyLink[country.toUpperCase()];
};
