import React from 'react';
import './ActivitySpinnerAnimateStyle.css';

const AcitivitySpinner = ({ backgroundStyle, spinnerStyle }) => (
  <div className={'activity-spinner-background'} style={backgroundStyle}>
    <div className={'rotating'} style={spinnerStyle}>
      <svg viewBox={'0 0 24 24'}>
        <g>
          <path
            fill={'#FFFFFF'}
            d='M0.1,17.5c-0.2,0.3-0.1,0.7,0.3,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.6-0.4l1.1-2.2C3.2,18,4.7,19.8,6.7,21
	c1.6,1,3.4,1.5,5.3,1.5c0.8,0,1.5-0.1,2.2-0.2c2-0.5,3.8-1.5,5.2-2.9c1.4-1.4,2.4-3.2,2.8-5.1c0.1-0.4-0.2-0.7-0.5-0.8
	c-0.4-0.1-0.7,0.2-0.8,0.5c-0.8,3.4-3.5,6.2-7,6.9c-2.3,0.5-4.6,0.1-6.6-1.1c-1.8-1-3.1-2.6-3.8-4.5l2.4,1.2c0.4,0.2,0.7,0,0.9-0.3
	c0.1-0.4,0-0.8-0.3-0.9l-3.7-1.8c-0.2-0.1-0.3-0.1-0.5,0c-0.2,0-0.3,0.2-0.4,0.3L0.1,17.5'
          />
          <path
            fill={'#FFFFFF'}
            d='M21.7,10.6c0.2-0.1,0.3-0.2,0.4-0.4l1.8-3.7c0.2-0.3,0.1-0.7-0.2-0.9c-0.4-0.1-0.8,0-0.9,0.3L21.7,8
	c-1.8-4.6-6.8-7.4-11.8-6.3c-2,0.5-3.8,1.5-5.2,2.9S2.3,7.8,1.9,9.7c-0.1,0.4,0.2,0.7,0.5,0.8h0.1c0.3,0,0.5-0.2,0.6-0.5
	c0.8-3.4,3.5-6.2,7-6.9c4.4-1,8.8,1.5,10.4,5.6l-2.4-1.2c-0.3-0.1-0.7,0-0.9,0.3c-0.2,0.4,0,0.7,0.3,0.9l3.7,1.8
	c0.1,0.1,0.2,0.1,0.3,0.1S21.6,10.6,21.7,10.6'
          />
        </g>
      </svg>
    </div>
  </div>
);

const OnScreenActivitySpinner = ({ backgroundStyle, spinnerStyle }) => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
    }}>
    <AcitivitySpinner
      backgroundStyle={backgroundStyle}
      spinnerStyle={spinnerStyle}
    />
  </div>
);

export { AcitivitySpinner, OnScreenActivitySpinner };
