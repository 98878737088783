const consentDeclarationFrSaunierDuval = `<h1>Formulaire de consentement</h1>
<p>
 Veuillez lire ce formulaire de consentement soigneusement et cliquez
 ci-dessus pour confirmer votre accord sur ces termes.
</p>
<ol>
 <li>
  Le présent formulaire de consentement vise à obtenir votre consentement au
  traitement, au transfert et à l'accès de vos données personnelles par votre
  installateur ou partenaire à des fins de surveillance, de diagnostic et de
  maintenance de votre système de chauffage Saunier Duval.
 </li>
 <li>
  Nous recueillons et traitons vos données personnelles via la solution de
  régulation MiGo, qui est connectée à votre système de chauffage Saunier
  Duval et ce, conformément à la Politique de confidentialité préalablement
  convenue entre vous et nous. Pour plus d'informations sur vos droits et le
  type de données que nous collectons et traitons, nous vous invitons à vous
  référer aux Conditions Générales d’Utilisation et à la Politique de
  confidentialité de l’application MiGo applicables que vous trouverez via le
  lien ci-dessous.
  <a href="https://view.vg-thermostat.com/legals/terms" target="_blank"
   >https://view.vg-thermostat.com/legals/terms</a
  >
 </li>
 <li>
  Nous avons mis à la disposition de votre installateur ou partenaire un
  logiciel lui permettant d'accéder et d'utiliser les données envoyées par
  votre MiGo à notre base de données et de se connecter à votre système de
  chauffage Saunier Duval. Cela permettra à votre installateur/partenaire de
  collecter des informations sur votre utilisation de votre système de
  chauffage Saunier Duval (y compris, sans limitation, l'état de
  fonctionnement, la mise en marche et/ou l'arrêt, la consommation, le message
  d'erreur, l'indication de défaut, les valeurs des capteurs, les
  mètres/compteurs et l'état des actionneurs, les périmètres et temps de
  fonctionnement, les programmes horaires, les connexions et informations
  concernant les incidents) et de modifier les paramètres et les profils ainsi
  que contrôler votre chauffage dans le cadre défini dans votre contrat avec
  votre installateur/partenaire et pendant la durée de celui-ci. Par
  conséquent, en traitant ou en accédant à vos données, votre installateur ou
  votre partenaire pourrait être en mesure de vous fournir des services
  d'entretien et de diagnostic et de réparer ou d'entretenir votre système de
  chauffage.
 </li>
 <li>
  En nous donnant ce consentement, vous nous garantissez que vous avez obtenu
  le consentement préalable des autres utilisateurs du système de chauffage
  Saunier Duval dans l’hypothèse et dans la mesure où vous n'êtes pas le seul
  propriétaire du système de chauffage Saunier Duval et de MiGo, par exemple
  si un autre propriétaire habite avec vous.
 </li>
 <li>
  Vos données personnelles seront traitées dans l'Espace Economique Européen
  (EEE), sauf indication contraire de notre part. En ce qui concerne les
  éventuels autres territoires dans lesquelles vos données seront traitées par
  votre installateur/partenaire, veuillez-vous rapprocher directement de ce
  dernier.
 </li>
 <li>
  Vous pouvez révoquer à tout moment votre accord pour le traitement de vos
  données personnelles et pour l'accès à votre système de chauffage dans le
  cadre de la surveillance et de l'entretien. Vous pouvez le faire en cliquant
  sur le lien dans le courriel reçu de Saunier Duval ou en nous contactant à
  <a href="mailto:servicepro@saunierduval.com">servicepro@saunierduval.com</a>.
  Cependant, veuillez noter qu'en révoquant votre consentement, votre
  installateur pourrait ne pas être en mesure d'effectuer les travaux
  d'entretien ou de dépannage de votre système de chauffage Saunier Duval, car
  il ne pourra plus surveiller ou accéder à distance à votre système de
  chauffage.
 </li>
 <li>
  Si vous avez des questions concernant ce formulaire de consentement et/ou le
  traitement de vos données personnelles, veuillez nous contacter à
  <a href="mailto:servicepro@saunierduval.com">servicepro@saunierduval.com</a>. Vous
  pouvez également contacter notre Délégué à la Protection des Données,
  Matthieu MELIN – Astura, 26 avenue George V 75008 Paris,
  <a href="mailto:dpo@astura.fr" target="_blank">dpo@astura.fr</a>.
 </li>
 <li>
  Vos droits sont énoncés dans la Politique de confidentialité
  <a href="https://view.vg-thermostat.com/legals/terms" target="_blank"
   >https://view.vg-thermostat.com/legals/terms</a
  >. Vous avez également le droit de déposer une plainte à tout moment auprès
  de la Commission Nationale Informatique et Libertés (CNIL, 3 place de
  Fontenoy, 75007 Paris), qui est l'autorité de contrôle en matière de
  protection des données (www.cnil.fr). Nous vous serions toutefois
  reconnaissants de nous donner l'occasion de répondre à vos préoccupations
  avant de vous adresser à l'autorité.
 </li>
 <li>
  Il est important que les données personnelles que nous détenons à votre
  sujet soient exactes et à jour. Veuillez nous tenir informés si vos données
  personnelles évoluent.
 </li>
</ol>
`;

export { consentDeclarationFrSaunierDuval };
