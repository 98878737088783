import ThemeColors from '../../Colors';

export default theme => ({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: ThemeColors.WHITE,
    fontFamily: 'VaillantApplianceInterface-Book',
    fontSize: 14,
    boxShadow: 'none',
    padding: '20px 0px 20px',
    gridArea: 'header',
  },
  languageSelector: {
    alignSelf: 'right',
    [theme.breakpoints.down('lg')]: {
      marginRight: 20,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 120,
    },
  },
  sloganPlaceholder: {
    marginBottom: '14px',
  },
  headerLogoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    paddingRight: 36,
    color: '#333333',
    fontSize: 16,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 120,
    },
  },
  headerLogoSloganSeparator: {
    margin: 5,
  },
});
