const consentDeclarationDkVaillant = `<h1>Samtykkeformular</h1>
<p>Læs denne samtykkeformular nøje og klik ovenfor for at bekræfte din accept af dens vilkår.</p>
<ol>
  <li>Denne samtykkeformular søger dit samtykke til behandling og overførsel af samt adgang til dine personlige data fra din udbyder ("partner") med henblik på overvågning og diagnosticering og service af dit Vaillant varmesystem.
  </li>
  <li>Vi  vil  indsamle og  behandle dine  personlige  data  ved  hjælp  af  en  dial-up access af en af vores internet modul eRELAX, sensoNET , eller myVAILLANT connect (”gateway”), som vil være forbundet til dit Vaillant varmesystem og i henhold til den fortrolighedspolitik, der var blevet aftalt mellem dig og os. For yderligere information om dine rettigheder og den type data, vi indsamler og behandler, henvises til de gældende vilkår og privatlivspolitik, som du finder via linket nedenfor.
  </li>
  <li>Vi har forsynet din partner med en software, hvormed han kan få adgang til og bruge de data, der sendes fra din gateway til vores database, og der kan også oprettes forbindelse til  dit Vaillant varmesystem. Dette giver din partner mulighed for at indsamle oplysninger om din brug af Vaillant varmesystemet (inklusive uden begrænsning,  driftsstatus,  til- og frakobling, forbrug, fejlmeddelelser, fejlindikation,  sensorværdier,  målere/tællere og aktuator status, drift og driftstider,  tidsprogrammer,  login og information om hændelser) og for at ændre indstillinger og profiler og styre din opvarmning til dette formål, mens du er tilsluttet under de tjenester, der leveres til  dig  af  din  partner. Derfor, ved  at  behandle/få  adgang  til  dine  data, kan din partner muligvis give dig service og diagnosticere og reparere og/eller vedligeholde dit varmesystem og/eller give dig mulighed for at fjernstyre dit Vaillant-varmesystem ved hjælp af applikationen leveret af din partner.
  </li>
  <li>Ved at give dette samtykke garanterer du os, at du har fået forudgående samtykke fra alle brugere af Vaillant varmesystemet, hvis du ikke er den eneste ansvarlige ejer af Vaillant varmesystemet og gatewayen, f.eks. hvis en anden husejer bor sammen med dig.
  </li>
  <li>Dine personoplysninger behandles inden for Det Europæiske Økonomiske Samarbejdsområde (EEA), medmindre vi informerer dig om andet. Henvis til ham om yderligere placeringer af din partners behandling af dine data.</li>
  </li>
  <li>Du kan tilbagekalde dit samtykke til behandling af dine personlige data og med hensyn til at få adgang til dit varmeanlæg i relation til monitorering og servicering af det til enhver tid. Du kan gøre dette ved at klikke på linket i den modtagne e-mail fra Vaillant eller ved at kontakte os på <a href="mailto:info@vaillant.dk">info@vaillant.dk</a>. Bemærk dog, at ved at tilbagekalde dit samtykke, kan din partner muligvis ikke udføre vedligeholdelsesarbejde eller fejlfindingstjenester på dit Vaillant-varmesystem, da han ikke er i stand til at overvåge/få adgang til varmesystemet.
  </li>
  <li>I tilfælde af at du har spørgsmål vedrørende denne samtykkeformular og/eller behandlingen af dine personlige data, bedes du kontakte os på <a href="mailto:info@vaillant.dk">info@vaillant.dk</a>.
  </li>
  <li>Dine rettigheder er angivet i nedenstående fortrolighedspolitik. Du har også ret til at indgive en klage til enhver tid til https://www.datatilsynet.dk/generelt-om-databeskyttelse/lovgivning. Vi vil dog sætte pris på chancen for at håndtere dine bekymringer, inden du henvender dig til myndigheden, så kontakt os i første omgang. 
  </li>
  <li> Det er vigtigt, at de personoplysninger, vi har er korrekte. Venligst hold os underrettet, hvis dine personlige data ændres under dit forhold til os. 
</ol>

<p><b>Jeg accepterer, at Vaillant GmbH bruger mine personlige data som beskrevet i samtykkeerklæringen ovenfor.</b></p>`;

export { consentDeclarationDkVaillant };
