export default theme => ({
  modalText: {
    margin: 5,
  },
  title: {
    fontSize: '2em',
    fontWeight: 'bold',
  },
  impressumContainer: {
    padding: 20,
    paddingLeft: 0,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  textContainerInline: {
    marginBottom: 30,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textInline: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pageTitleContainer: {
    marginLeft: 10,
  },
  imprintContainer: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '30px 40px',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: '650px',
    minWidth: '300px',
    top: '20%',
  },
});
