import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const NotificationsDialog = ({
  description,
  isNotificationsOpen,
  onNotificationsClose,
  classes,
}) => (
  <Dialog
    open={isNotificationsOpen}
    onClose={onNotificationsClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    onBackdropClick={onNotificationsClose}
    fullWidth={true}>
    <DialogTitle
      className={classes.dialogTitle}
      disableTypography={true}
      id='alert-dialog-title'>
      <h4>{description}</h4>
      <IconButton onClick={onNotificationsClose}>
        <Close />
      </IconButton>
    </DialogTitle>
  </Dialog>
);

NotificationsDialog.propTypes = {
  description: PropTypes.string,
  onNotificationsClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(NotificationsDialog);
