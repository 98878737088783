import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Text from '../../elements/Text';
import styles from './styles.js';

class ImprintComponent extends Component {
  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.imprintContainer}>
        <Text id='MS_IMPRINT_TITLE' className={classes.title} />
        <div className={classes.impressumContainer}>
          <div className={classes.textContainer}>
            <Text id='MS_IMPRINT_PROVIDER' />
            <Text className={classes.textBold}>Vaillant GmbH</Text>
            <Text className={classes.textBold}>Berghauser Str. 40</Text>
            <Text className={classes.textBold} id='MS_IMPRINT_ADDRESS' />
          </div>
          <div className={classes.textContainerInline}>
            <Text id='MS_IMPRINT_PHONE' />
            <Text className={classes.textBold}> +49 2191 180</Text>
          </div>
          <div className={classes.textContainer}>
            <Text id='MS_IMPRINT_LTD' />
            <div className={classes.textInline}>
              <Text id='MS_IMPRINT_REGISTERED' />
              <Text className={classes.textBold}> Remscheid</Text>
            </div>
            <div className={classes.textInline}>
              <Text id='MS_IMPRINT_REGISTRYCOURT' />
              <Text className={classes.textBold}>
                &nbsp;Amtsgericht Wuppertal HRB 11775
              </Text>
            </div>
          </div>
          <div className={classes.textContainer}>
            <Text id='MS_IMPRINT_MANAGING' />
            <Text className={classes.textBold}>
              &nbsp;Dr.-Ing. Norbert Schiedeck (CEO), Dr. Stefan Borchers, Dr.
              Andree Groos
            </Text>
          </div>
          <div className={classes.textContainerInline}>
            <Text id='MS_IMPRINT_CHAIRMAN' />
            <Text className={classes.textBold}>&nbsp;Dr. Matthias Blaum</Text>
          </div>
          <div className={classes.textContainerInline}>
            <Text id='MS_IMPRINT_VAT' />
            <Text className={classes.textBold}> DE 811142240</Text>
          </div>
        </div>
      </div>
    );
  }
}

ImprintComponent.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ImprintComponent);
