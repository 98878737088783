export default {
  MS_GDPR_TITLE: 'Anmodning om fjernadgang til dit varmesystem',
  MS_GDPR_DETAILEDTITLE:
    '{company} har anmodet om fjernadgang til dit varmesystem.',
  MS_GDPR_SUBTITLE:
    'For at {company} kan yde fjernsupport til dit varmesystem, kræves der tilladelse til databehandling af dit varmeapparat.',
  MS_USERINFO_TITLE: 'Din information:',
  MS_SALUTATION: 'Hilsen',
  MS_SALUTATION_LABEL: 'Fru/Hr',
  MS_SALUTATION_MRS: 'Fru',
  MS_SALUTATION_MR: 'Hr',
  MS_TITLE: 'Titel',
  MS_FIRSTNAME: 'Fornavn',
  MS_LASTNAME: 'Efternavn',
  MS_NAME: 'Navn',
  MS_STREET: 'Hus-/lejlighedsnummer eller navn',
  MS_HOUSENUMBER: 'Adresse linje 1',
  MS_ADDRESS_EXT: 'Adresse linje 2',
  MS_ZIPCODE: 'Postnummer',
  MS_CITY: 'By eller bydel',
  MS_COUNTRY: 'Land',
  MS_PHONE: 'Telefonnummer til kontaktperson',
  MS_EMAIL: 'E-mail',
  MS_MANDATORY: 'Obligatoriske felter',
  MS_PHONE_TOO_SHORT: 'Nummeret er for kort',
  MS_PHONE_TOO_LONG: 'Nummeret er for langt',
  MS_CONFIRMATION_TITLE: 'Bekræft samtykkeerklæring',
  MS_CONFIRMATION_PRE_TEXT: 'Jeg bekræfter hermed at',
  MS_CONFIRMATION_TEXT: 'din installatørs/serviceudbyders behandling af data',
  MS_CONFIRMATION_POST_TEXT: '{company}.',
  MS_CONFIRMATION_BUTTON: 'BEKRÆFT & SEND',
  MS_IMPRINT: 'Juridisk meddelelse',
  MS_GDPR: 'Privatlivspolitik',
  MS_TERMS: 'Vilkår for brug',
  MS_VAILLANT_LOGO_MOTO: 'Komfort til dit hjem',
  MS_DATA_PROTECTION: 'Privatlivets fred',
  MS_REQUIRED: 'Obligatorisk felt',
  MS_REQUIRED_EXPLANATION:
    '* Dette felt er obligatorisk og skal udfyldes for at afslutte din tilmelding.',
  MS_CLOSE_BUTTON: 'Jeg er enig',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Beklager, start venligst nummeret med',
  MS_EXPANDABLE_TITLE: 'Erklæring om samtykke',
  MS_NO_PARAM_GRANTED_CRAFTSMAN:
    'Du har allerede givet adgang til en installatør/serviceudbyder.',
  MS_NO_PARAM_OPEN_VAILLANT_MAIL:
    "Hvis du ønsker at tilbagekalde din bekræftelse af samtykkeerklæringen, skal du åbne den e-mail du modtog under registreringen og klikke på 'Opt out'.",
  MS_NO_PARAM_QUESTIONS_TO_VAILLANT:
    'For yderligere spørgsmål, kontakt venligst din installatør/serviceudbyder eller send en e-mail til {0}.',
  MS_ABOUT_US: 'Om os',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Jeg vil gerne besøge {0} websiden.',
  MS_OPTOUT_SALUTATION: 'Kære kunde,',
  MS_OPTOUT_QUESTION:
    'Ønsker du at opsige din bekræftelse af samtykkeerklæringen?',
  MS_OPTOUT_CHECKBOX:
    'Jeg forstår, at installatøren/serviceudbyderen ikke længere vil kunne få adgang til mine data.',
  MS_OPTOUT_CONFIRM: 'Ja, opsig',
  MS_OPT_IN_SUCCESS: 'Dit samtykke er sendt.',
  MS_OPTOUT_SUCCESS: 'Din opsigelse var vellykket.',
  MS_OPTOUT_FAIL:
    'Noget gik galt under behandlingen af din opsigelses-anmodning. Kontakt venligst vores service-hotline.',
  MS_IMPRINT_TITLE: 'Juridisk meddelelse',
  MS_IMPRINT_PROVIDER: 'Leverandør',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Tyskland',
  MS_IMPRINT_PHONE: 'Telefon:',
  MS_IMPRINT_LTD: 'Aktieselskab',
  MS_IMPRINT_REGISTERED: 'Registreret kontor:',
  MS_IMPRINT_REGISTRYCOURT: 'Registrer retten:',
  MS_IMPRINT_MANAGING: 'Administrerende direktør:',
  MS_IMPRINT_CHAIRMAN: 'Administrerende direktør:',
  MS_IMPRINT_VAT: 'VAT ID nummer',
  MS_GENERIC_ERROR_TITLE: 'Ups',
  MS_GENERIC_ERROR_EXPLANATION:
    'Der opstod en fejl under behandlingen af din anmodning. Prøv venligst igen.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE:
    'Beklager, vi kan ikke håndtere denne anmodning',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Tjek venligst, at du bruger den korrekte URL, som du har fået tilsendt via e-mail.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'Under reperation',
  MS_MAINTENANCE_MESSAGE:
    'Vi er i øjeblikket under reperation. Reperationen er færdig {endOfMaintenanceWindow, date, medium} kl. {endOfMaintenanceWindow, time, short}.',
};
