import en from './en';
import de from './de';
import es from './es';
import fr from './fr';
import hr from './hr';
import nl from './nl';
import ro from './ro';
import it from './it';
import dk from './dk';

export default { en, de, es, fr, hr, nl, ro, it, dk };
