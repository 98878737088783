import languageNames from './languageNames';
import { EnvEnum } from '../enums/EnvEnum';

function getInitialLanguage() {
  const availableLangs = languageNames.map(i => i.locale);
  var clientLanguage;

  if (navigator.language === null || navigator.language.length < 2) {
    return EnvEnum.FALLBACK_LANGUAGE;
  } else {
    clientLanguage = navigator.language.substring(0, 2).toLowerCase();
    return availableLangs.includes(clientLanguage)
      ? clientLanguage
      : EnvEnum.FALLBACK_LANGUAGE;
  }
}

export default getInitialLanguage;
