import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { injectIntl } from 'react-intl';
import ThemeColors from '../../Colors';
import { OptInClient } from '../../api';
import { EnvEnum } from '../../enums/EnvEnum';
import Button from '@material-ui/core/Button';
import Text from '../../elements/Text';
import { OnScreenActivitySpinner } from '../../elements/ActivitySpinner';
import styles from './styles';

const VaillantCheckbox = withStyles({
  root: {
    color: ThemeColors.GRAY_LIGHT,
    '&$checked': {
      color: ThemeColors.PRIMARY,
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

class DeregistrationForm extends Component {
  state = {
    loading: false,
    consentGiven: true,
  };

  handleFormSubmit = async values => {
    this.setState({ loading: true });

    const data = await OptInClient.revokeConsent(this.props.token);
    this.setState({ loading: false });
    if (!data.error) {
      this.props.onDeregistrationSuccess();
    } else {
      const contactEmail =
        EnvEnum.BRAND === 'vaillant'
          ? 'serviceAssist@vaillant-group.com'
          : 'ServicePro@vaillant-group.com';
      this.setState({
        notificationsMessage: `Submit failed, contact e-mail ${contactEmail}`,
      });
    }
  };

  render() {
    const { handleSubmit, intl, classes } = this.props;
    const handleChange = event => {
      this.setState({ consentGiven: !event.currentTarget.checked });
    };

    return (
      <div data-testid={'deregistration-form'} className={classes.container}>
        <Text id={'MS_OPTOUT_SALUTATION'} className={classes.textSalutation} />
        <Text id={'MS_OPTOUT_QUESTION'} className={classes.textQuestion} />
        <form
          className={classes.deregistrationForm}
          onSubmit={handleSubmit(this.handleFormSubmit)}>
          <FormGroup row>
            <FormControlLabel
              control={<VaillantCheckbox checked={!this.state.consentGiven} />}
              label={intl.formatMessage({ id: 'MS_OPTOUT_CHECKBOX' })}
              onChange={handleChange}
              style={{ marginLeft: 15 }}
            />
          </FormGroup>
          <div className={classes.submitBtnRow}>
            <Button
              type='submit'
              variant='contained'
              className={
                this.state.consentGiven
                  ? classes.submitBtnInactive
                  : classes.submitBtn
              }
              disabled={this.state.consentGiven}>
              {intl.formatMessage({ id: 'MS_CONFIRMATION_BUTTON' })}
            </Button>
          </div>
          {this.state.loading && <OnScreenActivitySpinner />}
        </form>
      </div>
    );
  }
}

let asReduxForm = reduxForm({
  form: 'deregistration-form',
})(injectIntl(DeregistrationForm));

asReduxForm = connect(state => ({
  initialValues: state.token,
}))(asReduxForm);

DeregistrationForm.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(asReduxForm);
