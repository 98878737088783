const consentDeclarationBeBulex = `
<p><strong>Toestemmingsformulier / Formulaire de consentement</strong></p>
<p><strong>Lees dit formulier zorgvuldig en klik hierboven als u akkoord gaat met de inhoud ervan. <br/><br/> Veuillez lire attentivement ce formulaire et cliquer ci-dessus si vous acceptez son contenu.</strong></p>
<ol>
<li>
    In dit formulier vragen wij uw toestemming voor de verwerking en overdracht van en de toegang tot uw persoonsgegevens door uw dienstverlener (&#39;partner&#39;), met het oog op de controle, de diagnose en het onderhoud van uw Bulex verwarmingssystem.
    <br/><br/>
    Dans ce formulaire, nous vous demandons de consentir au traitement, à la transmission et à l&#39;accès de vos données personnelles par votre prestataire de services (&quot;partenaire&quot;), dans le but de vérifier, diagnostiquer et entretenir votre système de chauffage Bulex.
</li>
<br/>
<li>
    Wij verzamelen en verwerken uw persoonsgegevens via een inbelverbinding van een van onze internetmodules MiGo, MiLink of MiGo Link (&#39;gateway&#39;), die wordt aangesloten op uw Bulex verwarmingssysteem en volgens het privacy beleid dat tussen u en ons werd overeengekomen. Wenst u meer informatie over uw rechten en het type van gegevens die wij verzamelen en verwerken, raadpleeg dan de voorwaarden van de gateway app en het privacy beleid via de onderstaande link.
    <br/><br/>
    Nous collectons et traitons vos données personnelles par le biais d&#39;une connexion internet de l&#39;un de nos modules Internet MiGo, MiLink ou MiGo Link (&quot;passerelle&quot;), qui est connecté à votre système de chauffage Bulex et conformément à la politique de confidentialité convenue entre vous et nous. Si vous souhaitez plus d&#39;informations sur vos droits et le type de données que nous collectons et traitons, veuillez consulter les conditions générales de l&#39;application passerelle et la politique de confidentialité via le lien ci-dessous.
</li>
<br/>
<li>
    Wij hebben uw partner voorzien van software waarmee hij toegang krijgt tot en gebruik kan maken van de gegevens die van uw gateway naar onze databank worden gestuurd, en ook verbinding kan maken met uw Bulex verwarmingssysteem. Uw partner kan op deze manier informatie verzamelen over uw gebruik van het Bulex verwarmingssysteem (onder meer over de werkingsstatus, het in- en/of uitschakelen, het verbruik, foutmeldingen, indicaties van storingen, sensorwaarden, meters/tellers en actuatorstatus, werkingsperimeters en doorlooptijden, tijdsblokken, login en informatie over incidenten), instellingen en profielen wijzigen en uw verwarming met dat doel regelen terwijl ze verbonden is tijdens de diensten die hij aan u verleent. Door uw gegevens te verwerken/raadplegen, kan uw partner u dan ook zijn diensten verlenen en een diagnose stellen, uw verwarmingssysteem herstellen en/of onderhouden, en/of u in staat stellen om uw Bulex verwarmingssysteem op afstand te bedienen met de door uw partner geleverde applicatie.
    <br/><br/>
    Nous avons fourni à votre partenaire un logiciel qui lui permet d&#39;accéder et d&#39;utiliser les données envoyées par votre passerelle à notre base de données, ainsi que de se connecter à votre système de chauffage Bulex. Ainsi, votre partenaire peut recueillir des informations sur votre utilisation du système de chauffage Bulex (y compris l&#39;état de fonctionnement, la mise en marche et/ou l&#39;arrêt, la consommation, les messages d&#39;erreur, les indications de dysfonctionnement, les valeurs des capteurs, les compteurs et l&#39;état des actionneurs, les périmètres de fonctionnement et les délais, les blocs horaires, les informations de connexion et d&#39;incident), modifier les paramètres et les profils et contrôler votre chauffage à cette fin lorsqu&#39;il est connecté pendant les services qu&#39;il vous fournit. Ainsi, en traitant/consultant vos données, votre partenaire peut vous fournir ses services et diagnostiquer, réparer et/ou entretenir votre système de chauffage, et/ou vous permettre de contrôler à distance votre système de chauffage Bulex en utilisant l&#39;application fournie par votre partenaire.
</li>
<br/>
<li>
    Door uw toestemming te verlenen, verzekert u ons dat u vooraf toestemming hebt gekregen van elke andere gebruiker van het Bulex verwarmingssysteem, indien en voor zover u niet de enige verantwoordelijke eigenaar bent van het Bulex verwarmingssysteem en de gateway, bijvoorbeeld als een andere huiseigenaar bij u woont.
    <br/><br/>
    En donnant votre consentement, vous nous assurez que vous avez obtenu le consentement préalable de tout autre utilisateur du système de chauffage Bulex, si et dans la mesure où vous n&#39;êtes pas le seul propriétaire responsable du système de chauffage Bulex et de la passerelle, par exemple si un autre propriétaire habite avec vous.
</li>
<br/>
<li>
    Uw persoonsgegevens worden verwerkt binnen de Europese Economische Ruimte ( <strong>EER</strong> ), behoudens andersluidende mededeling van onze kant. Wend u tot uw partner voor informatie over andere plaatsen waar uw gegevens door hem worden verwerkt.
    <br/><br/>
    Vos données personnelles sont traitées au sein de l&#39;Espace Economique Européen (<strong>EEE)</strong>, sauf avis contraire de notre part. Veuillez contacter votre partenaire pour obtenir des informations sur les autres lieux où vos données sont traitées par lui.
</li>
<br/>
<li>
    U mag uw toestemming voor het verwerken van uw persoonsgegevens en voor de toegang tot uw verwarmingssysteem met het oog op controle en onderhoud op elk ogenblik intrekken. Daartoe klikt u op de link in het e-mailbericht dat u van Bulex heeft ontvangen, of door met ons contact op te nemen via <a href="mailto:connectivity@bulex.be">connectivity@bulex.be</a> . Door uw toestemming in te trekken, bestaat echter de kans dat uw partner geen onderhoudswerken kan uitvoeren en geen problemen kan oplossen die zich voordoen aan uw Bulex verwarmingssysteem, aangezien hij het systeem niet op afstand kan controleren en analyseren.
    <br/><br/>
    Vous pouvez retirer à tout moment votre consentement au traitement de vos données personnelles et à l&#39;accès à votre système de chauffage à des fins de contrôle et de maintenance. Pour ce faire, cliquez sur le lien figurant dans le message électronique que vous avez reçu de Bulex, ou en nous contactant à l&#39;adresse <a href="mailto:connectivity@bulex.be">connectivity@bulex.be</a> . Cependant, en retirant votre consentement, il y a un risque que votre partenaire ne soit pas en mesure d&#39;effectuer les travaux de maintenance et de résoudre les problèmes survenant sur votre système de chauffage Bulex, car il ne pourra pas surveiller et analyser le système à distance.
</li>
<br/>
<li>
    Als u vragen heeft over dit toestemmingsformulier en/of over de verwerking van uw persoonsgegevens, kan u met ons contact opnemen via <a href="mailto:connectivity@bulex.be">connectivity@bulex.be</a> .
    <br/><br/>
    Si vous avez des questions concernant ce formulaire de consentement et/ou le traitement de vos données personnelles, veuillez nous contacter à l&#39;adresse <a href="mailto:connectivity@bulex.be">connectivity@bulex.be</a> .
</li>
<br/>
<li>
    Uw rechten worden beschreven in het privacy beleid via de onderstaande link. U heeft ook het recht om eender wanneer een klacht in te dienen bij de Gegevensbeschermingsautoriteit, de toezichthouder voor aangelegenheden rond gegevensbescherming (<a href="https://www.gegevensbeschermingsautoriteit.be/burger">https://www.gegevensbeschermingsautoriteit.be/burger</a> ). Wij zouden het echter op prijs stellen als wij de kans krijgen om uw klacht te behandelen voordat u zich tot deze instantie wendt. Gelieve dus eerst met ons contact op te nemen.
    <br/><br/>
    Vos droits sont décrits dans la politique de confidentialité via le lien ci-dessous. Vous avez également le droit de déposer une plainte auprès de l&#39;autorité de protection des données, le régulateur de la protection des données (<a href="https://www.autoriteprotectiondonnees.be/citoyen">https://www.autoriteprotectiondonnees.be/citoyen</a>), à tout moment. Toutefois, nous vous remercions de nous donner l&#39;occasion de traiter votre plainte avant que vous ne vous adressiez à cet organisme. Veuillez donc nous contacter en premier lieu.
</li>
<br/>
<li>
    Het is belangrijk dat de persoonsgegevens die wij over u bezitten, correct en up-to-date zijn. Gelieve ons op de hoogte te brengen indien uw persoonsgegevens tijdens onze relatie veranderen.
    <br/><br/>
    Il est important que les données personnelles que nous détenons à votre sujet soient exactes et à jour. Veuillez nous informer si vos données personnelles changent au cours de notre relation.
</li>
</ol>
`;
export { consentDeclarationBeBulex };
