const consentDeclarationHrVaillant = `<p class="has-line-data" data-line-start="0" data-line-end="1"><strong>Obrazac privole</strong></p>
<p class="has-line-data" data-line-start="2" data-line-end="3">Pozorno pročitajte obrazac Privole i kliknite iznad kako biste potvrdili da ste suglasni s njezinim uvjetima.</p>
<ol>
<li class="has-line-data" data-line-start="3" data-line-end="4">Ovim obrascem Privole želimo dobiti Vašu privolu za obradu i prijenos Vaših osobnih podataka (ime i prezime, adresa, serijski broj sustava grijanja) kao i za pristup istima od strane ovlaštenog servisera ili partnera Vaillant u svrhe praćenja i dijagnosticiranja te servisiranja vašeg sustava za grijanje Vaillant.</li>
<li class="has-line-data" data-line-start="4" data-line-end="5">Vaše osobne podatke prikupljamo i obrađujemo upotrebom komunikacijskog modula bežičnog sobnog  termostata eRELAX koji će biti povezan s Vašim sustavom za grijanje Vaillant te u skladu s Vaillant Pravilima o privatnosti koju ste prihvatili. Dodatne informacije o svojim pravima i o vrsti osobnih podataka koje prikupljamo i obrađujemo pročitajte u primjenjivim uvjetima i odredbama te Pravilima o privatnosti društva Vaillant čije poveznice možete naći u nastavku.</li>
<li class="has-line-data" data-line-start="5" data-line-end="6">Vašem serviseru ili partneru dali smo softver kojim se može spojiti i pristupiti podacima koji se šalju iz Vašeg termostata eRELAX u našu bazu podataka i upravljati njima te se također može povezati s Vašim sustavom za grijanje Vaillant. To će Vašem serviseru/partneru pomoći u prikupljanju informacija o Vašoj upotrebi sustava za grijanje Vaillant (uključujući, bez ograničenja, status rada, uključivanje i/ili isključivanje, potrošnju, poruku o pogrešci, dojavu kvara, vrijednosti senzora, status aktuatora, operativne perimetre i trajanja, vremenske programe, prijavu i informacije o incidentima) te pri promjeni postavki i profila, a pomoći će mu i da kontrolira Vaše grijanje u tu svrhu dok ste povezani tijekom usluga koje Vam pruža Vaš serviser/partner. Stoga Vam Vaš serviser ili partner obradom Vaših podataka i pristupom njima može pružiti uslugu i dijagnostiku te popravak i/ili održavanje Vašeg sustava za grijanje.</li>
<li class="has-line-data" data-line-start="6" data-line-end="7">Davanjem privole jamčite nam da ste dobili prethodnu privolu svih ostalih korisnika sustava za grijanje Vaillant ako i u mjeri u kojoj niste jedini odgovorni vlasnik sustava za grijanje Vaillant i povezanog termostata eRELAX, npr. ako s vama živi još jedan vlasnik kuće.</li>
<li class="has-line-data" data-line-start="7" data-line-end="8">Vaši osobni podaci obrađivat će se unutar Europskoga gospodarskog područja (EGP), osim ako Vas drugačije ne informiramo. Obratite se svojem serviseru/partneru o drugim lokacijama na kojima obrađuje Vaše podatke.</li>
<li class="has-line-data" data-line-start="8" data-line-end="9">U bilo kojem trenutku možete opozvati svoju privolu za obradu osobnih podataka i, kad je riječ o Vašem sustavu za grijanje, za njegovo praćenje i servisiranje. To možete učiniti tako da kliknete na poveznicu u poruci e-pošte koju ste dobili od Vaillanta ili tako da nam se obratite na <a href="mailto:info@vaillant.hr">info@vaillant.hr</a> . No imajte na umu da Vam zbog opoziva Vaše privole serviser neće moći pružiti gore navedene usluge održavanja ili otklanjanja problema za Vaš sustav za grijanje Vaillant putem eRELAX sustava jer ne može daljinski pratiti/pristupati Vašem sustavu za grijanje.</li>
<li class="has-line-data" data-line-start="9" data-line-end="10">Ako imate bilo kakvih pitanja u vezi s ovim obrascem privole i/ili obradom svojih osobnih podataka, obratite nam se na <a href="mailto:info@vaillant.hr">info@vaillant.hr</a> .</li>
<li class="has-line-data" data-line-start="10" data-line-end="11">Vaša prava navedena su web stranicama Vaillanta u Vaillantovim Pravilima o privatnosti. Imate pravo u bilo kojem trenutku uložiti prigovor nadležnom nadzornom tijelu ili na adresu društva Vaillant d.o.o. ili elektroničkom poštom na adresu: <a href="mailto:info@vaillant.hr">info@vaillant.hr</a> . No, cijenili bismo priliku da riješimo Vaše prigovore ili upite prije negoli se obratite nadležnom tijelu, stoga nam se slobodno odmah obratite.</li>
<li class="has-line-data" data-line-start="11" data-line-end="13">Važno je da su Vaši osobni podaci koje posjedujemo točni i aktualni. Tijekom trajanja našeg odnosa obavještavajte nas o promjenama svojih osobnih podataka.</li>
</ol>
`;

export { consentDeclarationHrVaillant };
