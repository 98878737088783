export default {
  MS_GDPR_TITLE: 'Remote Access Request To Your Heating System',
  MS_GDPR_DETAILEDTITLE:
    '{company} has requested remote access to your heating system.',
  MS_GDPR_SUBTITLE:
    'For {company} to provide remote support for your heating system, permission for the data processing of your heating appliance is required.',
  MS_USERINFO_TITLE: 'Your information:',
  MS_SALUTATION: 'Salutation',
  MS_SALUTATION_LABEL: 'Mrs./Mr.',
  MS_SALUTATION_MRS: 'Mrs.',
  MS_SALUTATION_MR: 'Mr.',
  MS_TITLE: 'Title',
  MS_FIRSTNAME: 'First name',
  MS_LASTNAME: 'Last name',
  MS_NAME: 'Name',
  MS_STREET: 'House/flat number or name',
  MS_HOUSENUMBER: 'Address line 1',
  MS_ADDRESS_EXT: 'Address line 2',
  MS_ZIPCODE: 'Postcode',
  MS_CITY: 'Town or City',
  MS_COUNTRY: 'Country',
  MS_PHONE: 'Contact telephone number',
  MS_EMAIL: 'E-mail',
  MS_MANDATORY: 'Mandatory fields',
  MS_PHONE_TOO_SHORT: 'Number too short',
  MS_PHONE_TOO_LONG: 'Number too long',
  MS_CONFIRMATION_TITLE: 'Confirm declaration of consent',
  MS_CONFIRMATION_PRE_TEXT: 'I hereby confirm to',
  MS_CONFIRMATION_TEXT:
    'the data processing by your installer/Service Provider',
  MS_CONFIRMATION_POST_TEXT: '{company}.',
  MS_CONFIRMATION_BUTTON: 'CONFIRM & SEND',
  MS_IMPRINT: 'Legal notice',
  MS_GDPR: 'Privacy Policy',
  MS_TERMS: 'Terms of use',
  MS_VAILLANT_LOGO_MOTO: 'Comfort for your home',
  MS_DATA_PROTECTION: 'Privacy',
  MS_REQUIRED: 'Mandatory field',
  MS_REQUIRED_EXPLANATION:
    '* This field is mandatory and needs to be filled in to finish your opt in.',
  MS_CLOSE_BUTTON: 'I Agree',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Sorry, please start the number with',
  MS_EXPANDABLE_TITLE: 'Declaration of consent',
  MS_NO_PARAM_GRANTED_CRAFTSMAN:
    'You have already granted access to an installer/service provider.',
  MS_NO_PARAM_OPEN_VAILLANT_MAIL:
    "If you want to revoke your confirmation for the declaration of consent, please open the email you received during registration and click on 'Opt out'.",
  MS_NO_PARAM_QUESTIONS_TO_VAILLANT:
    'For further questions please contact your installer/service provider or send an email to {0}.',
  MS_ABOUT_US: 'About us',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'I want to visit the {0} web page.',
  MS_OPTOUT_SALUTATION: 'Dear customer,',
  MS_OPTOUT_QUESTION:
    'Do you want to revoke (opt out) your confirmation for the declaration of consent?',
  MS_OPTOUT_CHECKBOX:
    'I understand that the installer/service provider will not be able to access my data anymore.',
  MS_OPTOUT_CONFIRM: 'Yes, revoke',
  MS_OPT_IN_SUCCESS: 'Your consent has been submitted successfully.',
  MS_OPTOUT_SUCCESS: 'Your Opt Out was successful.',
  MS_OPTOUT_FAIL:
    'Something went wrong while processing your opt out request. Please contact our service hotline.',
  MS_IMPRINT_TITLE: 'Legal notice',
  MS_IMPRINT_PROVIDER: 'Supplier',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Telephone:',
  MS_IMPRINT_LTD: 'Limited company',
  MS_IMPRINT_REGISTERED: 'Registered office:',
  MS_IMPRINT_REGISTRYCOURT: 'Register court:',
  MS_IMPRINT_MANAGING: 'Managing Director:',
  MS_IMPRINT_CHAIRMAN: 'Chairman of the Supervisory Board:',
  MS_IMPRINT_VAT: 'VAT ID no.',
  MS_GENERIC_ERROR_TITLE: 'Ooops',
  MS_GENERIC_ERROR_EXPLANATION:
    'An error has occurred when processing your request, please try again.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE: 'Sorry, we can’t handle this request',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Please verify, that you use the correct URL that was sent to you via email.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'Under maintenance',
  MS_MAINTENANCE_MESSAGE:
    'We are currently under maintenance. The maintenance window will stop on {endOfMaintenanceWindow, date, medium} at {endOfMaintenanceWindow, time, short}.',
};
