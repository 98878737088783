import ThemeColors from '../../Colors/index';

export default theme => ({
  inputLabel: {
    textAlign: 'left',
    marginTop: 40,
  },
  userInfoMask: {
    padding: '0px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  consentRadioLabel: {
    color: ThemeColors.PRIMARY,
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  simpleText: {
    marginTop: 10,
    textAlign: 'left',
    color: ThemeColors.GRAY_DARK,
    maxWidth: 600,
  },
  addressInputFields: {
    textAlign: 'left',
  },
  contactEmail: {
    textAlign: 'left',
  },
  phoneInput: {
    marginTop: 30,
    textAlign: 'left',
    width: '100%',
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: '1px',
    borderBottomColor: ThemeColors.GRAY_LIGHT,
  },
  countrySelect: {
    borderWidth: 0,
    backgroundColor: ThemeColors.WHITE,
  },
  phoneInputContainer: {
    flex: 1,
    maxWidth: 600,
    marginTop: 30,
  },
  requiredText: {
    marginTop: 30,
    textAlign: 'left',
    color: ThemeColors.GRAY_LIGHT,
    fontStyle: 'italic',
  },
  userInfoTitleContainer: {
    fontSize: 18,
    textAlign: 'left',
  },
  userInfoTitle: {
    fontSize: 18,
    textAlign: 'left',
  },
});
