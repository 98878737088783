// get the api configuration based on dynamically passed in environment variables,
// fall back to compile time configuration

export const getApiRoot = () => {
  const baseUrl =
    window.REACT_APP_BASE_API_URL || process.env.REACT_APP_BASE_API_URL;

  // TODO: Change BASE URL
  if (!!baseUrl) {
    return `${baseUrl}/service-connected-control/consent-portal-backend/v1/`;
  }

  // TODO: error-handling
  // TODO: how to act on client side here?
  // TODO: we need to log this to the backend -> sentry, ....????
  return 'misconfigured!!1!';
};

export const getSubscriptionKey = () => {
  const subKey = window.REACT_APP_SUB_KEY || process.env.REACT_APP_SUB_KEY;

  if (!!subKey) {
    return subKey;
  }

  // TODO: error-handling
  // TODO: how to act on client side here?
  // TODO: we need to log this to the backend -> sentry, ....????
  return 'misconfigured!!1!';
};

export const getBrand = () => {
  const brand =
    window.REACT_APP_BRAND || process.env.REACT_APP_BRAND || 'vaillant';
  if (brand.toLocaleLowerCase() !== 'vaillant') {
    return 'sdbg';
  }
  return brand;
};
