import ThemeColors from '../../Colors/index';

export default theme => ({
  expansionPanelRoot: {
    boxShadow: '0 10px 20px #c4c5c6', // TODO: Define #c4c5c6 color in ThemeColors
    backgroundColor: `${ThemeColors.WHITE} !important`,
  },
  expansionPanelHeader: {
    flex: 1,
    backgroundColor: `${ThemeColors.PRIMARY} !important`,
    color: `${ThemeColors.WHITE} !important`,
    borderRadius: 5,
    opacity: '1 !important',
  },
  expansionPanelContent: {
    textAlign: 'center',
  },
  expansionPanelDetails: {
    marginTop: 40,
  },
  expandableTitle: {
    color: ThemeColors.WHITE,
    fontFamily: 'VaillantApplianceInterface-Book',
  },
  closeBtn: {
    fontFamily: 'VaillantApplianceInterface-Book',
    padding: 20,
    backgroundColor: ThemeColors.WHITE,
    border: `2px solid ${ThemeColors.PRIMARY}`,
    float: 'right',
    color: ThemeColors.PRIMARY,
    marginTop: 20,
    marginBottom: 50,
    marginRight: 40,
    width: 250,
  },
  consentInputLabel: {
    position: 'relative',
    right: 15,
    textAlign: 'left',
    color: ThemeColors.BLACK_SOFT,
  },
  consentTitle: {
    marginTop: 100,
  },
  consentInputLabelLink: {
    color: ThemeColors.PRIMARY,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  consentRadioLabel: {
    color: 'green',
    fontSize: 20,
    fontWeight: 'bold',
  },
  confirmationTitleContainer: {
    marginTop: 40,
    textAlign: 'left',
  },
  radioWrapper: {
    display: 'flex',
    marginTop: 20,
    alignItems: 'center',
  },
  confirmationPreText: {
    display: 'inline',
    fontSize: 16,
  },
  confirmationText: {
    color: ThemeColors.PRIMARY,
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: 16,
  },
  confirmationPostText: {
    display: 'inline',
    fontSize: 16,
  },
});
