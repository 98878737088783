const consentDeclarationItHermannSaunierDuval = `
    <h1>Modulo di Conseo</h1>
    <h2>Controlla il Modulo di Consenso con attenzione e clicca sopra per confermare la tua accettazione dei termini.</h2>
    <ol>
      <li>
        <p>Questo Modulo di Consenso richiede di ottenere il tuo consenso all’elaborazione e al trasferimento, così come
          all’accesso, ai tuoi dati personali ad opera del tuo fornitore di servizi (“fornitore”) allo scopo di
          monitorare, fare diagnosi e facilitare manutenzioni del tuo sistema di riscaldamento Hermann Saunier Duval.
        </p>
      </li>
      <li>
        <p>Raccoglieremo e processeremo I tuoi dati personali tramite una connessione del Modulo Internet MiGo, che sarà
          connesso al tuo sistema di riscaldamentoHermann Saunier Duval secondo la politica di gestione della privacy
          che è stata concordata fra di noi. Per ulteriori informazioni sui tuoi diritti e il tipo di dati che
          raccogliamo e processiamo, fai riferimento ai termini di utilizzo della APP MiGo e all’accordo di Privacy
          che trovi riportato sotto</p>
      </li>
      <li>
        <p>Abbiamo fornito al tuo fornitore un Software col quale può accedere e utilizzare i dati inviati tramite il
          tuo MiGo al nostro database, software col quale può anche connettersi al tuo sistema di riscaldamento.
          Questoconsentirà al tuo fornitore di raccogliere importanti informazioni sul tuoutilizzo del sistema Hermann
          Saunier Duval (inclusi, a titolo esemplificativo,gli stati operativi, le accensioni e spegnimenti, i
          consumi, i messaggi di errore, le indicazioni di anomalie, I valori dei sensori, dei contatori e lo stato
          degli attuatori, i parametri operativi e le ore totali di utilizzo, le programmazioniorarie, i login e le
          informazioni sui guasti) e di cambiare i settaggi e i profili e di controllare da remoto il tuo sistema di
          riscaldamento nell’ambito del servizio che ti viene fornito. Quindi, accedendo e processando i dati del tuo
          impianto il tuo fornitore può fornirti un Servizio di monitoraggio, diagnosi, riparazione e manutenzione
          efficiente del tuo impianto.</p>
      </li>
      <li>
        <p>Concedendo il consenso ci comunichi altresì di aver ottenuto il consenso da tutti gli altri utilizzatori del
          sistema di riscaldamento Hermann Saunier Duval se e nella misura in cui non fossi il solo responsabile
          proprietario dell’impianto di riscaldamento Hermann Saunier Duval e del MiGo, ad esempio in presenza di un
          co-inquilino.</p>
      </li>
      <li>
        <p>I tuoi dati personali saranno processati esclusivamente all’interno dell’European Economic Area (EEA), a meno
          di comunicazione diversa da parte nostra. Riguardo alla posizione del trattamento dei dati da parte del
          fornitore, prendi direttamente contatto con lui.</p>
      </li>
      <li>
        <p>Puoi in qualunque istante revocare il consenso all’utilizzo dei tuoi dati personali con riguardo all’accesso
          al tuo sistema di riscaldamento ai fini del Servizio di monitoraggio e di assistenza. Puoi farlo
          semplicemente cliccando sul link presente nella mail ricevuta da Hermann Saunier Duval o contattandoci alla
          seguente mail: info.italia@Hermann Saunier Duval.com. Tuttavia, ricorda che la revoca del consenso il tuo
          fornitore non sarà in grado di fornirti il servizio di manutenzione e di intervento rapido per il tuo
          sistemadi riscaldamento Hermann Saunier Duval dal momento che non potrà più controllare/monitorare il tuo
          impianto da remoto.</p>
      </li>
      <li>
        <p>Nel caso tu abbia delle domande o dubbi in relazione a questo modulo di Consenso e/o al trattamento dei tuoi
          dati, contattaci alla mail: <a
            href="mailto:privacy.italia@Vaillant-group.com">privacy.italia@Vaillant-group.com</a>.</p>
      </li>
      <li>
        <p>I tuoi diritti sono enunciati nella Informativa sulla privacy di cui trovi i riferimenti sotto. Puoi sempre
          contattare il Garante per la protezione dei datipersonaliche è l’autorità che supervisiona le attività
          relative alla protezionedei dati personali (<a href="https://garanteprivacy.it"
            target="_blank">www.garanteprivacy.it</a>). Avremmo il piacere di conoscere e discutere le tue
          preoccupazioni prima di contattare le autorità, per cui prendi pure contatto con noi alle mail indicate.</p>
      </li>
      <li>
        <p>È importante che i tuoi dati in nostro possesso siano accurati e attuali. Percortesia contattaci in caso di
          variazioni durante il rapporto con Hermann Saunier Duval</p>
      </li>
    </ol>
   `;

export { consentDeclarationItHermannSaunierDuval };
