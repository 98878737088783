const consentDeclarationAtVaillant = `<h1>Einwilligungserklärung zur Datenverarbeitung durch Ihren Dienstleister</h1>
 <h2>Bitte lesen Sie dieses Einwilligungsformular sorgfältig durch und klicken Sie oben, um Ihr Einverständnis mit diesen Bedingungen zu bestätigen.</h2>
 <ol>
   <li>
     Mit dieser Einwilligungserklärung möchten wir, die Vaillant Group Austria GmbH mit Sitz in Clemens-Holzmeister-Strasse 6, 1100 Wien, Ihr Einverständnis für die Verarbeitung und Übermittlung sowie den Zugriff auf Ihre personenbezogenen Daten durch Ihren Dienstleister ("Partner") zum Zwecke der Überwachung und Diagnose und Wartung Ihres Vaillant Heizsystems einholen.
   </li>
   <li>
     Wir bzw. Unternehmen der Vaillant Group erheben und verarbeiten Ihre personenbezogenen Daten über einen DFÜ-Zugang eines unserer Internetmodule eRELAX und myVAILLANT connect ("Gateway"), der an Ihr Vaillant Heizsystem angeschlossen wird und gemäß der zwischen Ihnen und uns vereinbarten Datenschutzerklärung. Weitere Informationen zu Ihren Rechten und der Art der Daten, die wir erheben und verarbeiten, entnehmen Sie bitte den jeweiligen Bedingungen der Gateway App und der Datenschutzerklärung, die Sie in der E-Mail zu dieser Einwilligungserklärung verlinkt finden.
   </li>
   <li>
     Wir haben Ihrem Partner eine Software zur Verfügung gestellt, mit der er auf die von Ihrem Gateway an unsere Datenbank gesendeten Daten zugreifen und diese nutzen kann sowie eine Verbindung zu Ihrem Vaillant Heizsystem herstellen kann. Dies ermöglicht Ihrem Partner, Informationen über Ihre Nutzung des Vaillant Heizsystems (insbesondere Betriebszustand, Ein- und/oder Ausschalten, Verbrauch, Fehlermeldung, Störungsanzeige, Sensorwerte, Zähler und Betriebsstatus, Betriebsparameter und Laufzeiten, Zeitprogramme, Login und Informationen über Vorfälle) zu sammeln. Zu diesem Zweck kann Ihr Partner für Sie Leistungen erbringen, kann Zugriff auf Ihre Profile/Einstellungen im Gateway/Heizsystem nehmen oder ändern und Ihre Heizung fernsteuern. Durch die Verarbeitung/Zugriff auf Ihre Daten kann Ihr Partner ggf. Ihr Heizsystem diagnostizieren und soweit möglich bereits remote reparieren und/oder warten und/oder Ihnen ermöglichen, Ihr Vaillant Heizsystem über eine von Ihrem Partner bereitgestellte Anwendung fernzusteuern.
   </li>
   <li>
     Mit der Erteilung dieser Einwilligung versichern Sie uns, dass Sie die vorherige Zustimmung jedes anderen Nutzers des Vaillant Heizsystems eingeholt haben, wenn und soweit Sie nicht der allein verantwortliche Eigentümer des Vaillant Heizsystems und des Gateways sind, z.B. wenn ein anderer Hauseigentümer mit Ihnen zusammenlebt.
   </li>
   <li>
     Über die Grundsätze und die Art und Weise der Verarbeitung Ihrer Daten durch Ihren Partner, wenden Sie sich bitte an diesen.
   </li>
   <li>
     Sie können Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten und zum Zugriff auf Ihre Heizungsanlage in Bezug auf deren Überwachung und Wartung jederzeit widerrufen. Dies können Sie durch Anklicken des Links in der erhaltenen E-Mail von Vaillant oder durch Kontaktaufnahme mit uns unter <a href="mailto:myvaillantpro@vaillant.com">myvaillantpro@vaillant.com</a> durchführen. Bitte beachten Sie jedoch, dass Ihr Partner durch den Widerruf Ihrer Einwilligung möglicherweise nicht mehr in der Lage ist, Wartungsarbeiten oder eine Störungsbeseitigung an Ihrer Vaillant Heizungsanlage durchzuführen, da er nicht in der Lage ist, die Heizungsanlage aus der Ferne zu überwachen/auf sie zuzugreifen.
   </li>
   <li>
     Sollten Sie Fragen zu dieser Einwilligungserklärung und/oder der Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte an <a href="mailto:myvaillantpro@vaillant.com">myvaillantpro@vaillant.com</a> .
   </li>
   <li>
     Ihre Rechte sind in der verlinkten Datenschutzrichtlinie aufgeführt. Sie haben auch das Recht, jederzeit eine Beschwerde bei der österreichischen Datenschutzbehörde, deren aktuelle Kontaktdaten Sie unter <a href="www.dsb.gv.at/">https://www.dsb.gv.at/</a> finden, einzureichen. Wir würden uns jedoch freuen, wenn wir Ihre Bedenken ausräumen könnten, bevor Sie sich an die Behörde wenden, also kontaktieren Sie bitte uns zunächst.
   </li>
   <li>
     Es ist für die Leistungserbringung Ihres Partners wichtig, dass die persönlichen Daten, die wir von Ihnen erhalten haben, richtig und aktuell sind. Bitte informieren Sie uns daher, wenn sich Ihre persönlichen Daten ändern.
   </li>
 </ol>`;

export { consentDeclarationAtVaillant };
