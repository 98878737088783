import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import RegistrationForm from '../RegistrationForm/index';
import DeregistrationForm from '../DeregistrationForm/index';
import { EnvEnum } from '../../enums/EnvEnum';
import { CompanyName } from '../../enums/Companies';
import Text from '../../elements/Text/index';
import { injectIntl } from 'react-intl';
import SuccessComponent from '../SuccessComponent/index';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import ImprintComponent from '../ImprintComponent';

import { CompanyImage } from '../../Images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { OnScreenActivitySpinner } from '../../elements/ActivitySpinner';
import styles from './styles';
import MaintenanceComponent from '../MaintenanceComponent';

const MAIN_CONTENT_STATE = {
  LOADING: 'LOADING',
  IMPRINT: 'IMPRINT',
  ERROR: 'ERROR',
  OPT_IN_SUCCESS: 'SUCCESS',
  OPT_OUT: 'OPT_OUT',
  REGISTRATION_PERSONAL_DATA_INPUT: 'REGISTRATION_PERSONAL_DATA_INPUT',
  REGISTRATION_NO_DATA_INPUT: 'REGISTRATION_NO_DATA_INPUT',
  DEFAULT: 'DEFAULT',
  MAINTENANCE: 'MAINTENANCE',
};

const MainContentForm = ({
  classes,
  handleRegistrationSuccess,
  company,
  country,
  email,
  personalDataRequired,
  firstName,
  lastName,
  token,
}) => (
  <div className={classes.registrationFormContainer}>
    <div className={classes.gdprDetailsTitleContainer}>
      <Text id='MS_GDPR_DETAILEDTITLE' values={{ company }} />
    </div>
    <div className={classes.gdprDetailsSubTitleContainer}>
      <Text id='MS_GDPR_SUBTITLE' values={{ company }} />
    </div>
    <RegistrationForm
      country={country}
      token={token}
      email={email}
      firstName={firstName}
      lastName={lastName}
      company={company}
      personalDataRequired={personalDataRequired}
      onRegistrationSuccess={handleRegistrationSuccess}
    />
  </div>
);

class MainContent extends Component {
  state = {
    apiCallWasSuccessful: false,
  };

  componentDidMount() {
    document.title = CompanyName(EnvEnum.BRAND) + ' Consent';
  }

  handleRegistrationSuccess = () => {
    this.setState({ apiCallWasSuccessful: true });
  };

  mainContentBody = (
    classes,
    country,
    consentGiven,
    token,
    email,
    firstName,
    lastName,
    company,
    state,
    statusCode,
    endOfMaintenanceWindow,
  ) => {
    const currentState = Object.values(state).find(val => val);
    const mainContentStates = {
      [MAIN_CONTENT_STATE.LOADING]: (
        <OnScreenActivitySpinner className={classes.activitySpinner} />
      ),
      [MAIN_CONTENT_STATE.IMPRINT]: <ImprintComponent />,
      [MAIN_CONTENT_STATE.ERROR]: <ErrorComponent statusCode={statusCode} />,
      [MAIN_CONTENT_STATE.MAINTENANCE]: (
        <MaintenanceComponent endOfMaintenanceWindow={endOfMaintenanceWindow} />
      ),
      [MAIN_CONTENT_STATE.OPT_IN_SUCCESS]: (
        <SuccessComponent consentGiven={!consentGiven} country={country} />
      ),
      [MAIN_CONTENT_STATE.OPT_OUT]: (
        <DeregistrationForm
          country={country}
          token={token}
          email={email}
          company={company}
          onDeregistrationSuccess={this.handleRegistrationSuccess}
        />
      ),
      [MAIN_CONTENT_STATE.REGISTRATION_PERSONAL_DATA_INPUT]: (
        <MainContentForm
          classes={classes}
          country={country}
          company={company}
          email={email}
          personalDataRequired={true}
          name={''}
          surname={''}
          token={token}
          handleRegistrationSuccess={this.handleRegistrationSuccess}
        />
      ),
      [MAIN_CONTENT_STATE.REGISTRATION_NO_DATA_INPUT]: (
        <MainContentForm
          classes={classes}
          country={country}
          company={company}
          email={email}
          personalDataRequired={false}
          firstName={firstName}
          lastName={lastName}
          token={token}
          handleRegistrationSuccess={this.handleRegistrationSuccess}
        />
      ),
    };

    return mainContentStates[currentState];
  };

  render() {
    const {
      classes,
      country,
      company,
      email,
      firstName,
      lastName,
      personalDataRequired,
      error,
      consentGiven,
      token,
      imprint,
      maintenance,
      loading,
      status,
      endOfMaintenanceWindow,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <LazyLoadImage
            width={'100%'}
            effect={'blur'}
            src={CompanyImage.highRes}
            placeholderSrc={CompanyImage.lowRes}
            className={classes.heroImage}
            alt='main content picture'
          />
        </div>
        {this.mainContentBody(
          classes,
          country,
          consentGiven,
          token,
          email,
          firstName,
          lastName,
          company,
          {
            loading: loading && MAIN_CONTENT_STATE.LOADING,
            imprint: imprint && MAIN_CONTENT_STATE.IMPRINT,
            maintenance: maintenance && MAIN_CONTENT_STATE.MAINTENANCE,
            error: error && MAIN_CONTENT_STATE.ERROR,
            optInSuccess:
              this.state.apiCallWasSuccessful &&
              MAIN_CONTENT_STATE.OPT_IN_SUCCESS,
            optIn: consentGiven && MAIN_CONTENT_STATE.OPT_OUT,
            personalDataRequired:
              personalDataRequired &&
              MAIN_CONTENT_STATE.REGISTRATION_PERSONAL_DATA_INPUT,
            noPersonalDataRequired:
              !personalDataRequired &&
              MAIN_CONTENT_STATE.REGISTRATION_NO_DATA_INPUT,
          },
          status,
          endOfMaintenanceWindow,
        )}
      </div>
    );
  }
}

MainContent.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(injectIntl(MainContent));
