import React, { Fragment } from 'react';
import Radio from '@material-ui/core/Radio';
import Icon from '../../elements/Icon/index';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Text from '../../elements/Text/index';
import styles from './styles';

const ConsentRadio = ({
  classes,
  company,
  onRadioChange,
  onExpandTermsAndConditions,
}) => (
  <Fragment>
    <div className={classes.confirmationTitleContainer}>
      <Text id='MS_CONFIRMATION_TITLE' className={classes.consentRadioText} />
      &nbsp;
    </div>

    <div className={classes.radioWrapper}>
      <Radio
        color='primary'
        checkedIcon={<Icon icon='checkCircle' height={20} width={20} />}
        onChange={onRadioChange}
        style={{ left: -13 }}
      />
      <InputLabel htmlFor='radio-label' className={classes.consentInputLabel}>
        <span>
          <Text
            className={classes.confirmationPreText}
            id='MS_CONFIRMATION_PRE_TEXT'
            values={{ company }}
          />
          &nbsp;
          <span
            className={classes.consentRadioLabel}
            onClick={onExpandTermsAndConditions}>
            <Text
              className={classes.confirmationText}
              id='MS_CONFIRMATION_TEXT'
            />
          </span>
          &nbsp;
          <Text
            id='MS_CONFIRMATION_POST_TEXT'
            className={classes.confirmationPostText}
            values={{ company }}
          />
        </span>
      </InputLabel>
    </div>
  </Fragment>
);

ConsentRadio.propTypes = {
  classes: PropTypes.object,
  company: PropTypes.string,
  onRadioChange: PropTypes.func.isRequired,
  onExpandTermsAndConditions: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConsentRadio);
