import ThemeColors from '../../Colors';
export default theme => ({
  container: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: 5,
    padding: 20,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: 650,
    top: '20%',
  },
  deregistrationForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  textSalutation: {
    margin: '20px 30px',
    fontSize: 20,
  },
  textQuestion: {
    margin: '10px 30px',
  },
  submitBtnRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  submitBtn: {
    padding: '10px 20px',
    margin: '10px 20px',
    backgroundColor: ThemeColors.ACTION,
    borderRadius: 25,
    color: ThemeColors.WHITE,
  },
  submitBtnInactive: {
    padding: '10px 20px',
    margin: '10px 20px',
    backgroundColor: ThemeColors.INACTIVE,
    borderRadius: 25,
    color: ThemeColors.WHITE,
  },
});
