import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import appReducer from './appReducer';
import { reducer as formReducer } from 'redux-form';

const reducers = combineReducers({
  appReducer,
  form: formReducer,
});

const setMiddleware = () => {
  const middleware = applyMiddleware(thunk);
  if (process.env.NODE_ENV === 'development') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(middleware);
  }

  return compose(middleware);
};

export default createStore(reducers, setMiddleware());
