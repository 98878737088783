import React from 'react';
import Text from '../../elements/Text/index';
import { withStyles } from '@material-ui/core/styles';
import { EnvEnum } from '../../enums/EnvEnum';
import { CompanyName, CompanyHomePage } from '../../enums/Companies';
import { formatStrings } from '../../services/LocalisationMessageFormater';
import { injectIntl } from 'react-intl';
import styles from './styles';

const SuccessComponent = ({ classes, consentGiven, intl, country }) => {
  window.scrollTo(0, 0);
  return (
    <div className={classes.successContainer}>
      <Text
        className={classes.text}
        id={consentGiven ? 'MS_OPT_IN_SUCCESS' : 'MS_OPTOUT_SUCCESS'}
      />
      <a
        href={CompanyHomePage(EnvEnum.BRAND, country) || '#'}
        className={classes.webLink}>
        {formatStrings(
          intl.formatMessage({ id: 'MS_VISIT_HOMEPAGE' }),
          CompanyName(EnvEnum.BRAND),
        )}
      </a>
    </div>
  );
};
export default withStyles(styles)(injectIntl(SuccessComponent));
