const ThemeColors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  TRANSPARENT: 'transparent',
  ACTION: '#4CADD4',
  PRIMARY: '#cc0033',
  //SECONDARY: '#4cadd4',
  INACTIVE: '#CCCCCC',
  SIGNAL: '#ffd100',
  BLACK_SOFT: '#0C0033',
  GRAY_WHITE: '#F1F1F1',
  GRAY_LIGHT: '#9B9B9B',
  WIDGET_FOOTER: '#404041',
  PLACEHOLDER_TEXT: 'rgba(12,0,51,0.5)',
  INPUT_BORDER: '#C2BFCC',
  CONTAINER_BORDER: 'rgba(0, 0, 0, 0.3)',
  CONTAINER_BORDER_LIGHT: 'rgba(128, 128, 128, 0.3)',
  GRID_ROW_BACKGROUND: '#F5F5F6',
  ERROR: '#ffd100',
  BACKGROUND_COLOR_GRAY: '#f5f5f6',
  CUSTOMER_BACKGROUND_COLOR: '#f4cbd5',
  CUSTOMER_LIST_BACKGROUND_COLOR: '#fff6cc',
  CUSTOMER_DETAILS_BACKGROUND_COLOR: '#f4cbd5',
  CUSTOMER_DETAILS_BOX_SHADOW: 'inset 0px 20px 18px -10px #c995a1, inset 0px -20px 18px -10px #c995a1',
};

export default ThemeColors;
