import React from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { languageNames } from '../../i18n/index';
import selectLanguageAction from '../../actions/selectLanguageAction';

class SelectLanguage extends React.Component {
  render() {
    return (
      <Select
        value={this.props.language}
        onChange={e => this.props.selectLanguageAction(e.target.value)}
        name='locale'>
        {languageNames.map(l => (
          <MenuItem value={l.locale} key={l.locale}>
            {l.full}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = state => ({
  language: state.appReducer.language,
});

const mapDispatchToProps = dispatch => ({
  selectLanguageAction: locale => dispatch(selectLanguageAction(locale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectLanguage);
