const constentDeclarationRoVaillant = `<h1>
  Vă rugăm să citiți acest formular de acord cu atenție și faceți clic mai
  sus pentru a confirma acordul dvs. cu condițiile sale.
</h1>
<ol>
  <li>
    <p>
      Acest formular de aprobare urmărește să obțină consimțământul dvs. pentru
      prelucrarea și transferul, precum și accesul la datele dvs. personale de
      către instalatorul sau partenerul dvs. în scopul monitorizării și
      diagnosticării și deservirii sistemului dvs. de încălzire Vaillant.
    </p>
  </li>
  <li>
    <p>
      Vom colecta și prelucra datele dvs. personale folosind un acces telefonic
      al modulului internet eRELAX, care va fi conectat la sistemul dvs. de
      încălzire Vaillant și în conformitate cu politica de confidențialitate
      convenită între dvs. și noi. Pentru informații suplimentare despre
      drepturile dvs. și tipul de date pe care le colectăm și prelucrăm,
      consultați termenii și politica de confidențialitate aplicabile eRELAX, pe
      care le găsiți legate mai jos.
    </p>
  </li>
  <li>
    <p>
      Am oferit instalatorului sau partenerului dvs. un software prin care
      acesta poate accesa și utiliza datele trimise de la eRELAX în baza noastră
      de date și se poate conecta și la sistemul dvs. de încălzire Vaillant.
      Acest lucru va permite instalatorului / partenerului dvs. să colecteze
      informații despre utilizarea sistemului de încălzire Vaillant (inclusiv
      fără limitare, starea de funcționare, pornirea și / sau oprirea, consumul,
      mesajul de eroare, indicarea defecțiunii, valorile senzorului, contoarele
      / contoarele și actuatorul starea, perimetrele de funcționare și timpii de
      funcționare, programele de timp, conectarea și informațiile despre
      incidente) și pentru a schimba setările și profilurile și controla
      încălzirea în acest scop în timp ce sunteți conectat în timpul serviciilor
      oferite de către instalator / partener. Prin urmare, prin procesarea /
      accesarea datelor dvs., instalatorul sau partenerul dvs. poate fi în
      măsură să vă ofere servicii și să diagnostice și să repare și / sau să vă
      întrețină sistemul de încălzire.
    </p>
  </li>
  <li>
    <p>
      Dând acest consimțământ, ne garantezi că ați obținut acordul prealabil al
      fiecăruia dintre utilizatorii sistemului de încălzire Vaillant dacă și
      până acum nu sunteți singurul proprietar responsabil al sistemului de
      încălzire Vaillant și eRELAX, de ex. dacă un alt proprietar al casei
      locuiește cu tine.
    </p>
  </li>
  <li>
    <p>
      Datele dvs. personale vor fi prelucrate în Spațiul Economic European
      (SEE), cu excepția cazului în care vă informăm altfel. Despre alte locații
      ale prelucrării datelor dvs. de către instalator / partener, vă rugăm să
      vă adresați.
    </p>
  </li>
  <li>
    <p>
      Puteți să vă revocați consimțământul pentru prelucrarea datelor dvs.
      personale și cu privire la accesul la sistemul dvs. de încălzire în
      legătură cu monitorizarea și deservirea acestora în orice moment. Puteți
      face acest lucru făcând clic pe linkul din e-mailul primit de la Vaillant
      sau contactându-ne
      <a href="mailto:app-support@vaillant-group.com" target="_blank"
        >app-support@vaillant-group.com</a
      >. Cu toate acestea, vă rugăm să rețineți că, prin revocarea
      consimțământului dvs., instalatorul poate să nu poată furniza lucrări de
      întreținere sau servicii de depanare pentru sistemul dvs. de încălzire
      Vaillant, întrucât nu poate monitoriza / accesa de la distanță sistemul de
      încălzire.
    </p>
  </li>
  <li>
    <p>
      În cazul în care aveți întrebări în legătură cu acest Formular de
      Consimțământ și / sau prelucrarea datelor dvs. personale, vă rugăm să ne
      contactați la
      <a href="mailto:app-support@vaillant-group.com" target="_blank"
        >app-support@vaillant-group.com</a
      >
    </p>
  </li>
  <li>
    <p>
      Drepturile dvs. sunt menționate în politica de confidențialitate legată
      mai jos. De asemenea, aveți dreptul de a face o reclamație în orice moment
      la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter
      Personal, care este autoritatea de supraveghere pentru probleme de
      protecție a datelor
      <a href="https://www.dataprotection.ro/" target="_blank"
        >https://www.dataprotection.ro/</a
      >. Cu toate acestea, am aprecia șansa de a trata preocupările dvs. înainte
      de a vă apropia de autoritate, de accea vă rugăm să ne contactați în primă
      instanță.
    </p>
  </li>
  <li>
    <p>
      Este important ca datele personale pe care le deținem despre dvs. să fie
      corecte și actuale. Vă rugăm să ne țineți la curent dacă datele dvs.
      personale se schimbă în timpul relației cu noi.
    </p>
  </li>
</ol>`;

export { constentDeclarationRoVaillant };
