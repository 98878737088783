import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { EnvEnum } from '../../enums/EnvEnum';
import Text from '../../elements/Text/index';
import PrivacyPolicy from '../PrivacyPolicy';
import styles from './styles';

const ConsentExpansionPanel = ({ classes, onClosePanel, intl, country }) => (
  <ExpansionPanel
    defaultExpanded={true}
    classes={{ root: classes.expansionPanelRoot }}
    disabled={true}>
    <ExpansionPanelSummary
      expandIcon={<ExpandLessIcon />}
      classes={{
        expanded: classes.expansionPanelHeader,
        content: classes.expansionPanelContent,
      }}>
      <Text id='MS_EXPANDABLE_TITLE' className={classes.expandableTitle} />
    </ExpansionPanelSummary>
    <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
      <PrivacyPolicy country={country} brand={EnvEnum.BRAND} />
    </ExpansionPanelDetails>
    <Button
      variant='contained'
      className={classes.closeBtn}
      onClick={onClosePanel}>
      <Text id='MS_CLOSE_BUTTON' />
    </Button>
  </ExpansionPanel>
);

ConsentExpansionPanel.propTypes = {
  classes: PropTypes.object,
  onClosePanel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConsentExpansionPanel);
