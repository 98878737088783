import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Text from '../../elements/Text';
import { formatStrings } from '../../services/LocalisationMessageFormater';
import styles from './styles';
/**
 * Handle possible error conditions.
 *
 * Depending on the http code, we decide, which message to present the user:
 *
 * Scenarios:
 *  -1 -> no backend at all
 * 404 -> given token could not be found (either typo in URL or token already deleted / opted-out)
 * 4xx -> should not happen
 * 503 -> Service not available (e.g. DNS error in backend)
 * 5xx -> Generic error in backend
 *
 * XXX: Do we have to differentiate between GET, PUT and DELETE
 *
 */

const getErrorTexts = statusCode => {
  if (statusCode === 404) {
    return {
      title: 'MS_TOKEN_NOT_FOUND_ERROR_TITLE',
      subtitle: 'MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION',
    };
  }
  return {
    title: 'MS_GENERIC_ERROR_TITLE',
    subtitle: 'MS_GENERIC_ERROR_EXPLANATION',
  };
};

class ErrorComponent extends Component {
  render() {
    const { classes, statusCode } = this.props;
    const texts = getErrorTexts(statusCode);

    return (
      <div data-testid='error-component' className={classes.container}>
        <h1>
          <Text id={texts.title} />
        </h1>
        <Text className={classes.modalText}>
          {formatStrings(
            this.props.intl.formatMessage({
              id: texts.subtitle,
            }),
            <span className={classes.emailAddressText}>
              app-support@vaillant-group.com
            </span>,
          )}
        </Text>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(ErrorComponent));
