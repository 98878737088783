import React, { Component } from 'react';
import { change, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { OptInClient } from '../../api/index';
import {
  ConsentExpansionPanel,
  ConsentRadio,
} from '../TermsAndConditions/index';
import NotificationsDialog from '../NotificationsDialog/index';
import { EnvEnum } from '../../enums/EnvEnum';
import { injectIntl } from 'react-intl';
import { OnScreenActivitySpinner } from '../../elements/ActivitySpinner';
import UserInputMask from '../UserInputMask/UserInputMask';
import UserInfoMask from '../UserInfoMask/UserInfoMask';
import styles from './styles';

const FORM_NAME = 'registrationForm';

class RegistrationForm extends Component {
  state = {
    salutation: '',
    radioChecked: false,
    isPanelExpanded: false,
    company: '',
    notificationsMessage: null,
    isSubmitSuccessful: false,
  };

  handleSelectChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.props.dispatch(change(FORM_NAME, name, value));
  };

  handleRadioChange = (event, checked) => {
    this.setState({ radioChecked: checked });
  };

  expandTermsAndCondition = () => {
    this.setState({ isPanelExpanded: true });
  };

  collapseTermsAndCondition = () => {
    this.setState({ isPanelExpanded: false });
  };

  getMenuItems = (label, intl) => {
    switch (label) {
      case 'salutation':
        return [
          {
            label: intl.formatMessage({ id: 'MS_SALUTATION_LABEL' }),
            value: 'n',
          },
          {
            label: intl.formatMessage({ id: 'MS_SALUTATION_MR' }),
            value: 'Mr.',
          },
          {
            label: intl.formatMessage({ id: 'MS_SALUTATION_MRS' }),
            value: 'Mrs.',
          },
        ];
      default:
        break;
    }
  };

  handleNotificationsClose = () => {
    this.setState({ notificationsMessage: null });
  };

  formatPhoneNumber = phoneNumber => {
    return phoneNumber &&
      phoneNumber.length >= 3 &&
      phoneNumber.substring(1, 3) === '44'
      ? '00' + phoneNumber.substring(1)
      : phoneNumber;
  };

  handleFormSubmit = async values => {
    this.setState({ isLoading: true });

    const {
      salutation,
      firstName,
      lastName,
      street,
      houseNumber,
      postalCode,
      extension,
      city,
      phone,
    } = values;
    const { token, country, email } = this.props;

    const user = {
      salutation,
      firstName,
      lastName: lastName ? lastName : this.props.lastName,
      street,
      houseNumber,
      postalCode,
      extension,
      city,
      country,
      phone: phone && this.formatPhoneNumber(phone).replace(/ /g, ''),
      email,
    };

    const data = await OptInClient.giveConsent(token, user);
    this.setState({ isLoading: false });
    if (data.error === false) {
      this.props.onRegistrationSuccess();
    } else {
      const contactEmail =
        EnvEnum.BRAND === 'vaillant'
          ? 'serviceAssist@vaillant-group.com'
          : 'ServicePro@vaillant-group.com';
      this.setState({
        notificationsMessage: `Submit failed, contact e-mail ${contactEmail}`,
      });
    }
  };

  requiredFields = [
    'MS_FIRSTNAME',
    'MS_LASTNAME',
    'MS_STREET',
    'MS_HOUSENUMBER',
    'MS_ZIPCODE',
    'MS_CITY',
    'MS_PHONE',
  ];

  getFieldLabel(i18nId) {
    let labelText = this.props.intl.formatMessage({ id: i18nId });
    if (this.requiredFields.indexOf(i18nId) !== -1) {
      labelText += '*';
    }
    return labelText;
  }

  render() {
    const {
      handleSubmit,
      classes,
      company,
      country,
      email,
      firstName,
      lastName,
      intl,
      personalDataRequired,
    } = this.props;
    const { radioChecked, isPanelExpanded, notificationsMessage } = this.state;

    let userData;

    if (personalDataRequired) {
      userData = <UserInputMask country={country} email={email} />;
    } else {
      userData = (
        <UserInfoMask email={email} firstName={firstName} lastName={lastName} />
      );
    }

    return (
      <form
        data-testid={'registration-form'}
        className={classes.registrationForm}
        onSubmit={handleSubmit(this.handleFormSubmit)}>
        <NotificationsDialog
          description={notificationsMessage}
          isNotificationsOpen={!!notificationsMessage}
          onNotificationsClose={this.handleNotificationsClose}
        />

        {userData}

        <ConsentRadio
          company={company}
          onExpandTermsAndConditions={this.expandTermsAndCondition}
          onRadioChange={this.handleRadioChange}
        />

        {isPanelExpanded ? (
          <ConsentExpansionPanel
            onClosePanel={this.collapseTermsAndCondition}
            intl={intl}
            country={country}
          />
        ) : (
          <Button
            type='submit'
            variant='contained'
            className={classes.submitBtn}
            disabled={!radioChecked}>
            {this.getFieldLabel('MS_CONFIRMATION_BUTTON')}
          </Button>
        )}
        {this.state.isLoading && <OnScreenActivitySpinner />}
      </form>
    );
  }
}

RegistrationForm.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  company: PropTypes.string,
  country: PropTypes.string,
  personalDataRequired: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onRegistrationSuccess: PropTypes.func,
};

export default reduxForm({
  form: FORM_NAME,
})(withStyles(styles)(injectIntl(RegistrationForm)));
